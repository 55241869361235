import { APICALL } from 'helper/api/api';
import React from 'react';
import { DeleteOutline } from '@mui/icons-material';
import { toastifyError, toastifySuccess } from 'helper/Utility';
import { SOMETHING_ERR } from 'helper/Constant';
import { useState } from 'react';
import BTNLoading from './BTNLoading';

const DeleteItem = ({ url, data, callFun = null }) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(false)

    const deleteFun = async () => {
        const params = {
            id: data?._id,
        };
        setLoading(true)
        try {
            const res = await APICALL(url, 'POST', params);
            if (res?.status) {
                if (callFun) {
                    callFun();
                }
                setOpen(false);
                toastifySuccess(res?.message);
            } else {
                toastifyError(SOMETHING_ERR);
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || 'Server Error');
        } finally {
            setLoading(false)
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <button type="button" className="deletebtn-small" onClick={handleClickOpen}>
                <DeleteOutline />
            </button>

            {open && (
                <div className="modal fade show delete_modal"
                    style={{display: 'block',backgroundColor: 'rgba(0, 0, 0, 0.5)',inset: '0px', }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-2">
                            <div className="modal-body">
                                <h4>Are you sure you want to delete this item?</h4>
                                <div className="nn_discard_btn">
                                    {loading ?
                                        <BTNLoading className={"btn1"} /> :
                                        <button className="btn1" onClick={deleteFun}>Delete</button>}
                                    <button className="btn2" onClick={handleClose}> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DeleteItem;
