import React from "react";

import {
  Col,
  Container,
  Dropdown,
  Nav,
  Row,
  Tab,
  TabContainer,
} from "react-bootstrap";
import userdefault from "../../assets/img/userdefault.png";
import { Link } from "react-router-dom";
import img1 from "../../assets/img/products1.png";
import EmptyTab from "../..//assets/img/empty-tap.jpg";

const SellerDetails = () => {
  return (
    <>
      <div className="margindiv-header">
        <div className="Seller_edit_promain">
          <Container fluid>
            <TabContainer defaultActiveKey="selling">
              <Row>
                <Col xs={12} lg={3}>
                  <nav className="seller_tabs_nav">
                    <div className="seller_profile-img">
                      <div className="seller_edit_pro_img">
                        <img src={userdefault} alt="User" />
                      </div>
                      <div className="nn_edit_pro_ctn mt-3">
                        <h4 className="text-truncate">qwer</h4>
                        <h6>No Ratings</h6>
                      </div>
                      <div className="seller_edit_icons">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="fa fa-ellipsis-vertical"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#">Block User</Dropdown.Item>
                            <Dropdown.Item href="#">Report User</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <Nav variant="tabs" className="nav-fill">
                      <Nav.Item>
                        <Nav.Link eventKey="selling">Selling</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="sold">Sold</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </nav>
                </Col>

                <Col xs={12} lg={9} className="mt-lg-0 mt-4">
                  <Tab.Content>
                    {/* Selling Tab Content */}
                    <Tab.Pane eventKey="selling">
                      <div className=" selller_edit_sellpromain">
                        <div className="nn_edit_proname">Selling</div>
                        <div className="seller_edit_sellpro">
                          <Row  className="seller_edit_sellpro_inner">
                            {[...Array(7)].map((_, idx) => (
                              <Col
                                lg={3}
                                md={4}
                                sm={6}
                                className=" mb-4"
                                key={idx}
                              >
                                <div className="product-boxes">
                                  <div className="freeproducts">Free</div>
                                  <Link to="#">
                                    <div className="nn_homproductctn">
                                      <img alt="Product" src={img1} />
                                    </div>
                                    <div className="product-boxes_inner">
                                      <h4>Floral</h4>
                                      <p>
                                        Lorem Ipsum is simply dummy text of the
                                        printing...
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Tab.Pane>

                    {/* Sold Tab Content */}
                    <Tab.Pane eventKey="sold">
                      <div className=" selller_edit_sellpromain">
                        <div className="nn_edit_proname">Sold</div>

                        <div className="seller_edit_sellpro">
                          <Row  className="seller_edit_sellpro_inner">
                            {[...Array(1)].map((_, idx) => (
                              <Col
                                lg={3}
                                md={4}
                                sm={6}
                                className=" mb-4"
                                key={idx}
                              >
                                <div className="product-boxes">
                                  <Link to="#">
                                    <div className="nn_homproductctn">
                                      <img alt="Product" src={img1} />
                                    </div>
                                    <div className="product-boxes_inner">
                                      <h4>Floral</h4>
                                      <p>
                                        Lorem Ipsum is simply dummy text of the
                                        printing...
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {/* <div className="nn_empty_tab text-center">
                          <img src={EmptyTab} alt="Empty" />
                          <p>
                            <span>Sorry...</span> You have no sold products.
                          </p>
                        </div> */}
                        </div>
                      </div>
                    </Tab.Pane>

                    {/* Reviews Tab Content */}
                    <Tab.Pane eventKey="reviews">
                      <div className=" selller_edit_sellpromain">
                        <div className="nn_edit_proname">Reviews</div>
                        <div className="seller_edit_sellpro">
                          <div className="nn_empty_tab text-center">
                            <img src={EmptyTab} alt="Empty" />
                            <p>
                              <span>Sorry...</span> You have no reviews yet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </TabContainer>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SellerDetails;
