import React from 'react'
import { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"


const LocationCom = ({ formData, setFormData }) => {

    const handleLocationChange = async (value) => {
        setFormData((prevValue) => ({
            ...prevValue,
            location: value
        }));
    };

    const handleLocationSelect = async (value) => {
        try {
            try {
                const results = await geocodeByAddress(value)
                const latLng = await getLatLng(results[0])
                setFormData((prevValue) => ({
                    ...prevValue,
                    location: value,
                    latitude: latLng?.lat,
                    longitude: latLng?.lng,
                }))
                setSearchLatLan(latLng)
            } catch (error) {
                console.error("Error fetching location details", error)
            }
        } catch (error) {
            console.error('Error selecting address', error);
        }
    };
    return (
        <>
            <div className="group error">
                <PlacesAutocomplete
                    value={formData?.location}
                    name="location"
                    onChange={handleLocationChange}
                    onSelect={handleLocationSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="location_input mb-0 w-100">
                            {/* <span className="top_text mb-0">Address * <span className='dot_alert'></span></span> */}
                            <input className='mb-0' {...getInputProps({ placeholder: 'Start Typing' })} />
                            <div className="autocomplete-dropdown-container">
                                {loading ? <div>Loading...</div> : null}
                                {suggestions?.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? 'whitesmoke' : '#fff',
                                        padding: '10px 10px',
                                        cursor: 'pointer'
                                    };
                                    return (
                                        <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                            {suggestion.description}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        </>
    )
}

export default LocationCom