import React from 'react'

const FrontPageLoader = () => {
  return (
<div className='front_loader_for_page'>
<div class="snippet" data-title="dot-bricks">
      <div class="stage">
        <div class="dot-bricks"></div>
      </div>
    </div>
</div>
  )
}

export default FrontPageLoader