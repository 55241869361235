// assets
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Accessibility } from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import TaskIcon from '@mui/icons-material/Task';
import BookmarkIcon from '@mui/icons-material/Bookmark';
 
// ==============================|| UTILITIES MENU ITEMS ||============================== //
 
const utilities = {
    id: 'utilities',
    title: 'Pages',
    type: 'group',
    children: [
        {
            id: 'user',
            title: 'User Management',
            type: 'collapse',
            icon: GroupIcon,
            children: [
                {
                    id: 'Users',
                    title: 'Users',
                    type: 'item',
                    url: '/admin/users/1',
                    breadcrumbs: false
                },
                {
                    id: 'Vendors',
                    title: 'Vendors',
                    type: 'item',
                    url: '/admin/users/2',
                    breadcrumbs: false
                },
                {
                    id: 'Reported Users',
                    title: 'Reported Users',
                    type: 'item',
                    url: '/admin/report-users',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'Products',
            title: 'Products',
            type: 'collapse',
            icon: CategoryIcon,
            children: [
                {
                    id: 'Party Services',
                    title: 'Party Services',
                    type: 'item',
                    url: '/admin/products/partySupply',
                    breadcrumbs: false
                },
                {
                    id: 'Vendor Services',
                    title: 'Vendor Services',
                    type: 'item',
                    url: '/admin/products/vendorService',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'category',
            title: 'Category',
            type: 'collapse',
            icon: ReceiptLongIcon,
            children: [
                {
                    id: 'Party Categories',
                    title: 'Party Categories',
                    type: 'item',
                    url: '/admin/categories/partySupply',
                    breadcrumbs: false
                },
                {
                    id: 'Vendor Categories',
                    title: 'Vendor Categories',
                    type: 'item',
                    url: '/admin/categories/vendorService',
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'feedback',
            title: 'Manage Feedback',
            type: 'item',
            url: '/admin/feedback',
            icon: ThumbUpAltIcon,
            breadcrumbs: false
        },
        {
            id: 'reported-product',
            title: 'Reported Product',
            type: 'item',
            url: '/admin/reported-products',
            icon: ReportProblemIcon,
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: '/admin/orders',
            icon: StickyNote2Icon,
            breadcrumbs: false
        },
        {
            id: 'bookings',
            title: 'Bookings',
            type: 'item',
            url: '/admin/bookings',
            icon: BeenhereIcon,
            breadcrumbs: false
        },
        {
            id: 'newsletter',
            title: 'Newsletter',
            type: 'item',
            url: '/admin/newsletter',
            icon: UnsubscribeIcon,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Pages',
            type: 'item',
            url: '/admin/utils/pages',
            icon: TaskIcon,
            breadcrumbs: false
        },
        {
            id: 'util-shadow',
            title: 'Meta',
            type: 'item',
            url: '/admin/utils/meta',
            icon: BookmarkIcon,
            breadcrumbs: false
        },
        {
            id: 'util-typography',
            title: 'Blogs',
            type: 'item',
            url: '/admin/utils/blog',
            icon: WalletOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'shipping_size',
            title: 'Shipping Size',
            type: 'item',
            url: '/admin/shipping-size',
            icon: SettingsApplicationsIcon,
            breadcrumbs: false
        },
        {
            id: 'util',
            title: 'General Setting',
            type: 'item',
            url: '/admin/utils/general-setting',
            icon: SettingsApplicationsIcon,
            breadcrumbs: false
        },
    ]
};
 
export default utilities;