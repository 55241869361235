import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import myploscreen from "../../assets/img/footerBatch.png";
import playstore from "../../assets/img/badge_googleplay.svg";
import appstore from "../../assets/img/badge_appstore.svg";
const AppPlayStore = () => {
    return (
        <>
            <section className="free-app-store">
                <Container>
                    <Row>
                        <Col md="10" className="mx-auto">
                            <Row className="align-items-end">
                                <Col md="4" className="d-md-block d-none">
                                    <div className="myplo_footertopimg">
                                        <img alt="" src={myploscreen} className="img-fluid" />
                                    </div>
                                </Col>
                                <Col md="8 px-md-5">
                                    <div className="mpplo_download_appcnt">
                                        <h3>
                                            GET THE <span className="free-app">FREE</span>{" "}
                                            <span>APP</span> NOW!
                                        </h3>
                                        <div className="myplo_footerapps">
                                            <div className="myplo_googlepay">
                                                <img alt="" src={playstore} />
                                            </div>
                                            <div className="myplo_googlepay">
                                                <img src={appstore} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default AppPlayStore