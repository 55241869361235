import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import { useLocation, useNavigate } from 'react-router';
import { auth } from 'helper/Utility';
import { useEffect } from 'react';
import 'App.css'

// ==============================|| APP ||============================== //

const Admin = () => {
    const customization = useSelector((state) => state.customization);
    const authData = auth('admin')
    const pathname = useLocation()?.pathname
    const navigate = useNavigate()
    useEffect(() => {
        if (authData?.token) {
            if(authData?.role != "admin"){
                navigate(`/`)
            }
        }else{
            navigate(`/admin/login`)
        }
    }, [pathname]);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default Admin;
