import React from 'react'
import emtycard from "../../assets/img/empty-tap.jpg";

const FrontNoData = ({msg}) => {
    return (
        <>
            <div className="myplo_notFound">
                <img src={emtycard} alt="empty" />
                <h5> {msg}</h5>
                <span>
                    Try looking for something else or check again soon!
                </span>
            </div>
        </>
    )
}

export default FrontNoData