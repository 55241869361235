import { auth, defaultIMG, imgBaseURL } from 'helper/Utility'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoginAuth from './LoginModal'
import { useState } from 'react'
import { currencyText } from 'helper/Constant'

const ProductBox = ({ item }) => {
    const navigate = useNavigate()
    const [loginModal, setLoginModal] = useState(false)
    const handleCloseLoginModal = () => setLoginModal(false);
    const handleRedirect = (type, data) => {
        if (type === "chat") {
            auth('user') ?
                navigate(`/chat/conversation`, { state: data })
                :
                setLoginModal(true)
        }
    }
    return (
        <>
            <div className="product-boxes">
                {/* <div className="freeproducts">Free</div> */}

                <Link to={`/product-details/${item?._id}`} className="nn_homproductctn">
                    <img alt="" src={item?.gallery?.length > 0 ? imgBaseURL() + item?.gallery[0] : defaultIMG} />
                </Link>
                <div className="product-boxes_inner">
                    <h2><Link to={`/product-details/${item?._id}`} className="product-title">  {item?.title}</Link></h2>
                    {
                        item?.location &&
                        <p><i className="fa fa-location-dot"></i> {item?.location}</p>
                    }
                    <p className="price-products">{currencyText}  {item?.price}</p>
                    {/* <div className="myplochatimg">
                        <button className="myplonn_chatBtn" onClick={() => handleRedirect("chat", item)}>
                            <svg viewBox="0 0 24 24" width="24" height="24" fill="#fff">
                                <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                            </svg>
                        </button>
                        <button className="favbtn"> <i className="fa fa-heart active"></i> </button>
                    </div> */}
                </div>
            </div>

            <LoginAuth show={loginModal} handleClose={handleCloseLoginModal} />

        </>
    )
}

export default ProductBox