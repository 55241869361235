import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from './config';
import { FrontContextProvider } from 'helper/context/FrontContextProvider';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <Router basename={config.basename}>
            <FrontContextProvider>
                <App />
            </FrontContextProvider>
        </Router>
    </Provider>
);

serviceWorker.unregister();
