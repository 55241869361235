import React, { useState } from "react";

// import { useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import contactImg from "../../../src/assets/img/contactImg.png";
import playstore from "../../../src/assets/img/badge_googleplay.svg";
import appstore from "../../../src/assets/img/badge_appstore.svg";
import myploscreen from "../../../src/assets/img/footerBatch.png";
import { APICALL } from "helper/api/api";
import { toastifyError, toastifySuccess } from "helper/Utility";
import { SERVER_ERR, SOMETHING_ERR } from "helper/Constant";
import BTNLoading from "helper/my-com/BTNLoading";
const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [feedback, setFeedback] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({
    'submit': false,
  })
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) newErrors.email = "Email Field is Required";
    if (!feedback) newErrors.feedback = "Feedback Field is Required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setLoading({ ...loading, 'submit': true })
      try {
        const params = {
          "email" : email,
          "type" : category,
          "feedback" : feedback
        }
        const res = await APICALL("user/submitFeedback", "post", params)
        if (res?.status) {
          setCategory("")
          setEmail("")
          setFeedback("")
          toastifySuccess(res?.message)
        } else {
          toastifyError(SOMETHING_ERR)
        }
      } catch (error) {
        toastifyError(error?.response?.data?.message || SERVER_ERR)
      } finally {
        setLoading({ ...loading, 'submit': false })
      }
    }
  };

  return (
    <>
      <div className="margindiv-header">
        <div className="contact_page_myplo">
          <Container>
            <Row>
              <Col md={12} lg={6} xl={6}>
                <div className="contact-outer_myplo">
                  <div className="myplo-title-h2 d-block">
                    <h2 className="ps-0 mb-3">Contact Us</h2>
                    <p>Please tell us more about your request..</p>
                  </div>

                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formEmail" className="mb-4">
                      <Form.Label>
                        Your Email Address<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        isInvalid={!!errors.email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formCategory" className="mb-4">
                      <Form.Label>How Can We Help You ?</Form.Label>
                      <Form.Control
                        as="select"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option value="">Select one...</option>
                        <option value="Technical issues">Technical issues</option>
                        <option value="Shipping & Delivery issues">Shipping & Delivery issues</option>
                        <option value="Cancellation & Refunds">Cancellation & Refunds</option>
                        <option value="Payment">Payment</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid"> {errors.category}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formFeedback" className="mb-lg-5 mb-4">
                      <Form.Label>Description<span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        value={feedback}
                        isInvalid={!!errors.feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid"> {errors.feedback}</Form.Control.Feedback>
                    </Form.Group>
                    {
                      loading?.submit ?
                        <BTNLoading className={"w-100 cmn-btn2"} />
                        :
                        <Button type="submit" className="w-100 cmn-btn2"> Submit</Button>
                    }
                    <p className="checkWrap2">
                      Our Customer Care Team will get back to you as soon as possible.
                    </p>
                  </Form>
                </div>
              </Col>

              <Col md={12} lg={6} xl={6} className="d-lg-block d-none">
                <img src={contactImg} alt="Contact" />
              </Col>
            </Row>
          </Container>
        </div>
        <section className="free-app-store">
          <Container>
            <Row>
              <Col md="10" className="mx-auto">
                <Row className="align-items-end">
                  <Col md="4" className="d-md-block d-none">
                    <div className="myplo_footertopimg">
                      <img alt="" src={myploscreen} className="img-fluid" />
                    </div>
                  </Col>
                  <Col md="8 px-md-5">
                    <div className="mpplo_download_appcnt">
                      <h3>
                        GET THE <span className="free-app">FREE</span>{" "}
                        <span>APP</span> NOW!
                      </h3>
                      <div className="myplo_footerapps">
                        <div className="myplo_googlepay">
                          <img alt="" src={playstore} />
                        </div>
                        <div className="myplo_googlepay">
                          <img src={appstore} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default ContactUs;
