import { Rating } from '@mui/material';
import { APICALL } from 'helper/api/api';
import { SOMETHING_ERR } from 'helper/Constant';
import BTNLoading from 'helper/my-com/BTNLoading'
import { toastifyError, toastifySuccess } from 'helper/Utility';
import React from 'react'
import { useState } from 'react';

const RateBtn = ({ data, callFun = null }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(2);
    const rate = async () => {
        const params = {
            "productId": data?.productId?._id ,
            "ratingCount": value,
            "bookingId": data?._id
        };
        setLoading(true)
        try {
            const res = await APICALL("user/rateProduct", 'POST', params);
            if (res?.status) {
                if (callFun) {
                    callFun();
                }
                setOpen(false);
                toastifySuccess(res?.message);
            } else {
                toastifyError(SOMETHING_ERR);
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || 'Server Error');
        } finally {
            setLoading(false)
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <button  type="button" onClick={() => handleClickOpen()} className='myplo_addressbtn1'>Rate</button>
            {open && (
                <div className="modal fade show delete_modal"
                    style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', inset: '0px', }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-2">
                            <div className="modal-body">
                              <div className='rate_box text-center'>
                                <h5 className='mb-4'>Rating</h5>
                              <Rating
                                    name="simple-controlled mt-3 mb-3"
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                />
                              </div>
                                <div className="nn_discard_btn">
                                    {loading ?
                                        <BTNLoading className={"btn1"} /> :
                                        <button className="btn1" onClick={rate}>Rate</button>}
                                    <button className="btn2" onClick={handleClose}> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default RateBtn