import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { imgBaseURL } from "helper/Utility";

const ThumbnailSlider = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const imagesWithBaseUrl = images?.map(image => `${imgBaseURL()}${image}`);
  return (
    <div className="thumbSlider_box">
      {
        imagesWithBaseUrl?.length > 0 &&
        <>
          <Swiper
            spaceBetween={10}
            thumbs={{
              swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[Navigation, Thumbs]}
            className="mainSwiper"
          >
            {imagesWithBaseUrl.map((img, i) => (
              <SwiperSlide key={i}>
                <img src={img} alt={img} />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={3}
            freeMode
            navigation
            watchSlidesProgress
            modules={[Navigation, Thumbs]}
            className="thumbsSwiper"
          >
            {imagesWithBaseUrl.map((img, i) => (
              <SwiperSlide key={i}>
                <img src={img} alt={`Thumbnail for ${i}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      }

    </div>
  );
};

export default ThumbnailSlider;
