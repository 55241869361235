import React from 'react'
import Footer from 'views/front/inc/Footer';
import Header from 'views/front/inc/Header';
import AOS from "aos";
import "aos/dist/aos.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./App.css";
import "./Responsive.css";
import { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import { APICALL } from 'helper/api/api';
import { useState } from 'react';
const Front = ({ cmp, header }) => {
    const Component = cmp;
    const page = useLocation()?.pathname.replace(/^\//, "");
    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(null);
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
        AOS.refresh();
    }, []);

    useEffect(() => {
        getPageContentFun();
    }, [page]);

    useEffect(() => {
        if (pageData) {
            document.title = pageData.metaTitle || "MYPLO";
            updateMetaTags();
        }
    }, [pageData]);

    const getPageContentFun = async () => {
        const pageName = page || "home"
        setLoading(true);
        try {
            const res = await APICALL("admin/getAllPageMeta", "post", { pageUrl: pageName });
            if (res?.status) {
                setPageData(res?.data);
                setLoading(false);
            }else{
                setPageData(null);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const updateMetaTags = () => {
        const metaTitle = document.querySelector('meta[property="og:title"]');
        if (metaTitle) {
            metaTitle.setAttribute("content", pageData.metaTitle || "MYPLO");
        } else {
            const newMeta = document.createElement("meta");
            newMeta.property = "og:title";
            newMeta.content = pageData.metaTitle || "MYPLO";
            document.head.appendChild(newMeta);
        }

        const metaDescription = document.querySelector(
            'meta[property="og:description"]'
        );
        if (metaDescription) {
            metaDescription.setAttribute(
                "content",
                pageData.metaDesc || "Description"
            );
        } else {
            const newMeta = document.createElement("meta");
            newMeta.property = "og:description";
            newMeta.content = pageData.metaDesc || "Description";
            document.head.appendChild(newMeta);
        }
    };
    return (
        <>
            <Header />
            <Component />
            <Footer />
            <Outlet />
        </>
    )
}

export default Front