import React from 'react'
import EmptyTab from '../../assets/images/empty-tap.jpg';

const NoData = () => {
  return (
    <div className="nn_empty_tab text-center">
      <img src={EmptyTab} alt="Empty" style={{height: "300px", width : "300px"}}/>
      <p>
        <span>Sorry...</span> There are no data to display.
      </p>
    </div>
  )
}

export default NoData