import React from 'react'
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"

const GetCurrentLocation = ({className, formData, setFormData}) => {
    const handleGetLocation = () => {
        if (!navigator.geolocation) {
            console.log("Geolocation is not supported by your browser.");
            return;
        }
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const address = await geocodeLatLng(latitude, longitude);
                    const results = await geocodeByAddress(address);
                    const latLng = await getLatLng(results[0]);
                    setFormData((prevValues) => ({
                        ...prevValues,
                        location: address,
                        latitude: latLng?.lat,
                        longitude: latLng?.lng,
                    }));
                } catch (error) {
                    console.error("Error fetching address:", error);
                }
            },
            (err) => {
                console.log(
                    "Unable to retrieve your location. Make sure location services are enabled."
                );
            }
        );
    };

    const geocodeLatLng = async (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat, lng };

        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === "OK" && results[0]) {
                    resolve(results[0].formatted_address);
                } else {
                    reject("Geocoder failed due to: " + status);
                }
            });
        });
    };
    return (
        <>
            <button type="button" className={className} onClick={() => handleGetLocation()}>  <i className="fa fa-location-crosshairs"></i> Set Current Location</button>
        </>
    )
}

export default GetCurrentLocation