/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Menu, Tab, MenuItem, Typography, TableContainer, TablePagination, InputAdornment, Button } from '@mui/material';
import { DeleteOutline, Edit, MoreVertOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import moment from 'moment';
import { APICALL } from 'helper/api/api';
import DeleteItem from 'helper/my-com/DeleteItem';
import { SOMETHING_ERR, TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from 'helper/Constant';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PageLoader from 'helper/my-com/PageLoader';
import NoData from 'helper/my-com/NoData';
import ImgTable from 'helper/my-com/ImgTable';
import { toastifyError, toastifySuccess } from 'helper/Utility';
export default function ReportedProducts() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const [isForm, setIsForm] = useState(false)
    const [loader, setLoader] = useState({
        'pageLoading': false
    })
    const getDataListFun = async () => {
        setLoader({ ...loader, 'pageLoading': true })
        try {
            const res = await APICALL('admin/getReportedProducts', "post", {})
            console.log("getReportedProducts",res)
            if (res?.status) {
                setData(res?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader({ ...loader, 'pageLoading': false })
        }
    }
    useEffect(() => {
        getDataListFun()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span> Reported Products
                </typography>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box className="boxtbale">
                    {
                        loader?.pageLoading ?
                            <PageLoader />
                            :
                            <>
                                {
                                    data.length > 0 ?
                                        <>

                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>S.No</TableCell>
                                                            <TableCell>Email</TableCell>
                                                            <TableCell>Title</TableCell>
                                                            <TableCell>Image</TableCell>
                                                            <TableCell>Condition</TableCell>
                                                            <TableCell>Location</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Selling Status</TableCell>
                                                            <TableCell>Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                            <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                <TableCell component="th" scope="row">
                                                                    {i + 1}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.userId?.email}</TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {row.productId?.title}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row"><ImgTable image={row?.productId?.gallery[0]} /> </TableCell>
                                                                <TableCell>{row.productId?.condition}</TableCell>
                                                                <TableCell>{row.productId?.location}</TableCell>
                                                                <TableCell>{row.productId?.status ? "Active" : "Deactive"}</TableCell>
                                                                <TableCell>{row.productId?.sellingStatus}</TableCell>
                                                                <TableCell>{moment(row.createdAt).format("DD-MMM-YYYY")}</TableCell>
                                                                <TableCell align="right">
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                component="div"
                                                count={data?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />

                                        </>
                                        :
                                        <NoData />
                                }
                            </>
                    }
                    

                </Box>
            </Box>
        </>
    );
}