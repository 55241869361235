/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Menu, Tab, MenuItem, Typography, TableContainer, TablePagination, InputAdornment, Button } from '@mui/material';
import { Edit, MoreVertOutlined } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from 'react';
import moment from 'moment';
import { APICALL } from 'helper/api/api';
import DeleteItem from 'helper/my-com/DeleteItem';
import { currencySign, SOMETHING_ERR, TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from 'helper/Constant';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PageLoader from 'helper/my-com/PageLoader';
import NoData from 'helper/my-com/NoData';
import { toastifyError, toastifySuccess } from 'helper/Utility';
import { useNavigate } from 'react-router';
export default function OrderList() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
  const [isForm, setIsForm] = useState(false)
  const [loader, setLoader] = useState({
    'pageLoading': false
  })
  const getDataListFun = async () => {
    setLoader({ ...loader, 'pageLoading': true })
    try {
      const res = await APICALL('admin/getAllOrders', "post", {})
      console.log("OrderList", res)
      if (res?.status) {
        setData(res?.data)
      } else {
        setData([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoader({ ...loader, 'pageLoading': false })
    }
  }

  useEffect(() => {
    getDataListFun()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (row) => {
    navigate(`/admin/order-details/234234`, {state: row})
  }
  return (
    <>
      <div className="section-tittle d-flex justify-content-between align-items-center">
        <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
          <span class="shape"></span>Order List
        </typography>
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box className="boxtbale">
          {
            loader?.pageLoading ?
              <PageLoader />
              :
              <>
                {
                  data.length > 0 ?
                    <>

                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>S.No</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell>Full Name</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Price</TableCell>
                              {/* <TableCell>Status</TableCell> */}
                              <TableCell>Date</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, i) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell component="th" scope="row">{i + 1}</TableCell>
                                  <TableCell component="th" scope="row" className='text-uppercase'>{row?._id}</TableCell>
                                  <TableCell component="th" scope="row">{row?.userId?.fullName}</TableCell>
                                  <TableCell component="th" scope="row">{row.userId?.email}</TableCell>
                                  <TableCell component="th" scope="row">{currencySign}{row.productId?.price}</TableCell>
                                  {/* <TableCell component="th" scope="row">{row.status}</TableCell> */}
                                  <TableCell>{moment(row.createdAt).format('DD-MMM-YYYY')}</TableCell>
                                  <TableCell align="right">
                                    <div className="but-groups-action">
                                      <button onClick={() => handleClick(row)} className="editbtn-small"><VisibilityIcon /></button>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                        component="div"
                        count={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />

                    </>
                    :
                    <NoData />
                }
              </>
          }

        </Box>
      </Box>
    </>
  );
}
