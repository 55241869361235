import React, { useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import playstore from "../../assets/img/badge_googleplay.svg";
import appstore from "../../assets/img/badge_appstore.svg";
import myploscreen from "../../assets/img/footerBatch.png";
import emtycard from "../../assets/img/empty-tap.jpg";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect } from "react";
import { APICALL } from "helper/api/api";
import { defaultIMG, imgBaseURL } from "helper/Utility";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
import ProductBox from "views/Component/ProductBox";
import AppPlayStore from "views/Component/AppPlayStore";
import { useFrontDataContext } from "helper/context/FrontContextProvider";
const ProductListing = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const keyword = searchParams.get("keyword");
  const latitude = searchParams.get("latitude");
  const longitude = searchParams.get("longitude");
  const { contextLoader, getCategoryListFunc, categoryList } = useFrontDataContext()

  const [value, setValue] = useState({ min: 0, max: 30 });
  const [categoryData, setCategoryData] = useState(null)
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  const [conditions, setConditions] = useState(["Excellent", "Good", "Fair"])
  const [isFilterApply, setIsFilterApple] = useState(false)
  useEffect(() => {
    if (id) {
      getCategoryListFunc()
      getProductListFunc()
    }
  }, [])

  const handleConditionChange = (event) => {
    const { name, checked } = event.target;
    setConditions((prevConditions) =>
      checked
        ? [...prevConditions, name]
        : prevConditions.filter((condition) => condition !== name)
    );
  };


  const getProductListFunc = async () => {
    setLoading(true)
    try {
      const categorySerachParams = {
        "categoryId": id,
        "weight": value,
        "condition": conditions
      }
      const homeSearchParams = {
        "keyword": keyword,
        "latitude": latitude,
        "longitude": longitude
      }
      const params = id ? categorySerachParams : homeSearchParams
      const apiEnd = id ? "user/getProductsByCategoryId" : "user/getProductsByHomeFilter"
      const res = await APICALL(apiEnd, 'post', params)
      if (res?.status) {
        setCategoryData(res?.categoryData)
        setProductList(res?.data)
      } else {
        setProductList(null)
      }
    } catch (error) {
      setProductList(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isFilterApply) {
      getProductListFunc()
    }
  }, [isFilterApply])

  const handlefilter = () => {
    setIsFilterApple(true)
    getProductListFunc()
  }

  const handleClear = () => {
    setValue({ min: 0, max: 30 })
    setConditions(["Excellent", "Good", "Fair"])
    setIsFilterApple(false)
  }

  return (
    <>
      <div className="margindiv-header">
        <section className="prodcuts_listing_page">
          {
            loading ? <FrontPageLoader />
              :
              <Container>
                {
                  id &&
                  <Row>
                    <Col xl="8" lg="7" md="10">
                      <div className="myplo-title-h2 d-block">
                        <h2 className="ps-0 mb-2"><span className="shape"></span>{categoryData?.categoryName}</h2>
                        <p>{categoryData?.description}</p>
                      </div>
                    </Col>
                  </Row>
                }

                <div className="prodcuts_listing_flex mt-2">

                  {id &&
                    <div className="prodcuts_listing_colleft">
                      <div className="filertcler">
                        <p>FILTERS </p>
                        <button type="button" className="cleBtmn" onClick={() => handleClear()}> Clear Filters</button>
                      </div>
                      {categoryData?.conditionFilter &&
                        <div className="nn_fliterctn px-3">
                          <label className="d-block mb-2">Condition</label>
                          <FormControlLabel
                            className="labelName-filter"
                            control={
                              <Checkbox
                                name="Excellent"
                                checked={conditions.includes("Excellent")}
                                onChange={handleConditionChange}
                              />
                            }
                            label="Excellent"
                          />
                          <FormControlLabel
                            className="labelName-filter"
                            control={
                              <Checkbox
                                name="Good"
                                checked={conditions.includes("Good")}
                                onChange={handleConditionChange}
                              />
                            }
                            label="Good"
                          />
                          <FormControlLabel
                            className="labelName-filter"
                            control={
                              <Checkbox
                                name="Fair"
                                checked={conditions.includes("Fair")}
                                onChange={handleConditionChange}
                              />
                            }
                            label="Fair"
                          />
                        </div>
                      }

                      {categoryData?.featured &&
                        <>
                          <label className="d-block px-3 mb-2">Weight (Max 30 Lbs)</label>
                          <div className="inpydeanrr">
                            <InputRange maxValue={30} minValue={0} value={value} onChange={(newValue) => setValue(newValue)} />
                          </div>
                        </>
                      }

                      {(categoryData?.conditionFilter || categoryData?.featured) ?
                        <div className="border-top pt-2">
                          <button type="button" className="cmn-btn2 w-100" onClick={() => handlefilter()}>Save Filters</button>
                        </div>
                        :
                        <>
                          <div className="text-center">
                            <hr />
                            <h6> OOPS! NO FILTER FOR THIS CATEGORY</h6>
                          </div>
                        </>
                      }

                    </div>}

                  <div className="prodcuts_listing_colright">
                    {
                      productList?.length > 0 ?
                        <div className="row">
                          {
                            productList?.map((item, i) => (
                              <div className="col-lg-4 col-md-4 col-sm-6  mb-4" key={i}>
                                <ProductBox item={item} />
                              </div>
                            ))
                          }

                        </div>
                        :
                        <div className="myplo_notFound">
                          <img src={emtycard} alt="empty" />
                          <h5> {id ? "OOPS! NO RESULTS NEAR YOU" : "No products found that match your search criteria. Please try refining your filters or keywords."}</h5>
                          <span> Try looking for something else or check again soon!</span>
                          {
                            !id &&
                            <Link to={`/`} className="d-block mt-2">Back</Link>
                          }
                        </div>
                    }
                  </div>

                </div>
              </Container>
          }

        </section>
        <AppPlayStore />
      </div>
    </>
  );
};

export default ProductListing;
