import React, { useState } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import CategoryList from "./CategoryList";
import { Link, useNavigate } from "react-router-dom";
import InputRange from "react-input-range";
import { Checkbox, FormControlLabel } from "@mui/material";
import "react-input-range/lib/css/index.css";
import { useFrontDataContext } from "helper/context/FrontContextProvider";

const FilterModalHeader = ({ show, handleClose }) => {
  const [value, setValue] = useState({ min: 0, max: 30 });
  const { getCategoryListFunc, categoryList } = useFrontDataContext()
  const navigate = useNavigate()
  // useEffect(() => {
  //   getCategoryListFunc("partySupply");
  // }, []);
const [categoryId, setCategoryId] = useState("")

const handleClick = () => {
  navigate(`product-listing/${categoryId}`)
}
  const [fullscreen] = useState(true);
  return (
    <Modal
      fullscreen={fullscreen}
      show={show}
      onHide={handleClose}
      dialogClassName="Filter_custom-modal"
    >
      <Modal.Header>
        <Button variant="link" onClick={handleClose}>
          <i className="fa fa-arrow-left"></i>
        </Button>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-lg-4">
        <Container className="px-0">
          <div className="myplo-title-h2">
            <h2>
              <span className="shape"></span>Party Supplies
            </h2>
            <Link className="see_all-all" to="/categories">See All</Link>
          </div>
          <div>
            <div className="fitler-cardsadd">
              <select className="form-control" name="category" id="selectval" onChange={(e)=>setCategoryId(e.target.value)}>
                <option value="">Choose a Category</option>
                {
                  categoryList?.map((item, i) =>(
                    <option value={item?._id}>{item?.categoryName}</option>
                  ))
                }
              </select>
            </div>
            <div className="prodcuts_listing_colleft w-100 mt-4">
              {/* <div className="filertcler">
                <p>FILTERS </p>
                <button type="submit" className="cleBtmn">
                  Clear Filters
                </button>
              </div>
              <div className="nn_fliterctn px-3">
                <label className="d-block mb-2">Condition</label>
                <FormControlLabel
                  className="labelName-filter"
                  control={<Checkbox defaultChecked />}
                  label="Excellent"
                />
                <FormControlLabel
                  className="labelName-filter"
                  control={<Checkbox defaultChecked />}
                  label="Good"
                />
                <FormControlLabel
                  className="labelName-filter"
                  control={<Checkbox defaultChecked />}
                  label="Fair"
                />
              </div>
              <label className="d-block px-3 mb-2">Weight (Max 30 Lbs)</label>
              <div className="inpydeanrr">
                <InputRange
                  maxValue={30}
                  minValue={0}
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </div> */}

              <div className="border-top pt-2">
                <button type="submit" className="cmn-btn2 w-100">
                  Save Filters
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default FilterModalHeader;
