import React, { useState } from "react"
import logo from "../../../assets/img/logo.png"
import avatar from "../../../assets/img/avatar.png"
import Dropdown from "react-bootstrap/Dropdown"
import { Link, useNavigate } from "react-router-dom"
import SellYourPartyleftover from "views/Component/SellYourPartyleftover"
import FilterModalHeader from "views/Component/FilterModalHeader"
import LoginAuth from "views/Component/LoginModal"
import { auth, imgBaseURL, toastifySuccess } from "helper/Utility"
import { useFrontDataContext } from "helper/context/FrontContextProvider"
import { APICALL } from "helper/api/api"
import { useEffect } from "react"

const Header = () => {
    const { contextLoader, userInfo, getUserInfoFun, generalSetting } = useFrontDataContext()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)

    const handleShow = () => setShowModal(true)
    const handleClose = () => setShowModal(false)
    const [showModal1, setShowModal1] = useState(false)

    const handleShow1 = () => setShowModal1(true)
    const handleClose1 = () => setShowModal1(false)
    const [isPaneOpen, setIsPaneOpen] = React.useState(false)
    const openSlidingPane = () => {
        setIsPaneOpen(true)
    }

    const [isOpen, setIsOpen] = useState(false)
    const toggleOffCanvas = () => {
        setIsOpen(!isOpen)
    }
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [isHeaderFixed] = useState(false)

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen)
    }

    const handleLogout = () => {
        localStorage.clear()
        toggleOffCanvas()
        navigate("/")
        toastifySuccess("Logout Succesfully !")
    }
    const [notificationList, setNotificationList] = useState([])
    const getNotifications = async () => {
        const res = await APICALL("user/userNotifications", "post", {})
        if (res?.status) {
            setNotificationList(res?.data)
        } else {
            setNotificationList([])
        }
    }
    const handleClick = (type, data) => {
        if (auth("user")) {
            if (type === "apicall") {
                getNotifications()
            } else if (type === "sale") {
                navigate(`/profile/${auth("user")?.id}`, { state: "sold" })
                readNotifications(data?._id)
            }
        } else {
            setShowModal1(true)
        }
    }

    const readNotifications = async (id) => {
        await APICALL("user/readNotification", "post", { notificationId: id })
    }

    return (
        <>
            <header className={`header dektopheader ${isHeaderFixed ? "pxl-header-fixed" : ""}`}>
                <div className="container">
                    <div className="inner-menu">
                        <div className="logo">
                            <Link to="/">
                                <img src={imgBaseURL() + generalSetting?.logo} alt="Logo" />
                            </Link>
                        </div>
                        <nav className={`nav ${isNavOpen ? "active" : ""}`}>
                            <ul className={`nav-list ${isNavOpen ? "active" : ""}`}>
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={toggleNav}>
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pages/about-us" className="nav-link" onClick={toggleNav}>
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/categories" className="nav-link" onClick={toggleNav}>
                                        Party Supplies
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/vendor" className="nav-link" onClick={toggleNav}>
                                        Vendor Services
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/blog" className="nav-link" onClick={toggleNav}>
                                        Blog
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link" onClick={toggleNav}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="headerright">
                            {!auth("user") && (
                                <div className="login-fix-mobile">
                                    <button className="sellstuffbtn" type="button" onClick={handleShow1}>
                                        LOGIN
                                    </button>
                                </div>
                            )}

                            <div className="ressell-fix-mobile">
                                <button className="sellstuffbtn" type="button" onClick={openSlidingPane}>
                                    <i className="far fa-plus-square"></i>Sell your party leftovers
                                </button>
                            </div>

                            {/* <div className="resfilte-filter-dektop">
                <button type="button" onClick={handleShow}>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <path d="M17.724 18.5c-.577 1.515-2.03 2.59-3.729 2.59-1.7 0-3.152-1.075-3.729-2.59h-6.85c-.755 0-1.369-.672-1.369-1.5s.614-1.5 1.37-1.5h6.885c.6-1.467 2.027-2.5 3.693-2.5s3.094 1.033 3.693 2.5h2.99c.756 0 1.37.672 1.37 1.5s-.614 1.5-1.37 1.5h-2.954zM5.312 5.5C5.912 4.033 7.34 3 9.006 3s3.094 1.033 3.693 2.5h7.885c.756 0 1.37.672 1.37 1.5s-.614 1.5-1.37 1.5h-7.85c-.577 1.515-2.028 2.59-3.728 2.59-1.7 0-3.152-1.075-3.73-2.59H3.324c-.756 0-1.37-.672-1.37-1.5s.614-1.5 1.37-1.5h1.99zm3.694 3.307c.939 0 1.712-.783 1.712-1.762 0-.98-.773-1.762-1.712-1.762-.94 0-1.712.783-1.712 1.762 0 .98.773 1.762 1.712 1.762zm4.99 10c.938 0 1.711-.783 1.711-1.762 0-.98-.773-1.762-1.712-1.762-.94 0-1.712.783-1.712 1.762 0 .98.773 1.762 1.712 1.762z"></path>
                  </svg>
                </button>
                <div className="clsoeres"></div>
              </div> */}

                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="notificationClass">
                                    <div className="notificationClass" onClick={() => handleClick("apicall")}>
                                        {userInfo?.notificationCount > 0 && <span className="count-dwons">{userInfo?.notificationCount}</span>}
                                        <i className="far fa-bell"></i>
                                    </div>
                                </Dropdown.Toggle>

                                {notificationList?.length > 0 && (
                                    <Dropdown.Menu className="notification-inner">
                                        <div className="msg-header">
                                            <p className="msg-header-title">Notifications</p>
                                        </div>
                                        <div className={`notificationbost_msg ${notificationList?.length > 3 ? "scrollable" : ""}`}>
                                            {notificationList?.map((item, i) => (
                                                <Dropdown.Item  key={i}>
                                                    <div className="flex-grow-1" style={{ cursor: "pointer" }} onClick={() => handleClick(item?.type, item)}>
                                                        <h6 className="msg-name text-capitalize">{item?.type}</h6>
                                                        <p className="msg-info">{item?.message}</p>
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>
                                )}
                            </Dropdown>

                            {auth("user") && (
                                <div className="chat-bu-header">
                                    <Link to="/chat/conversation">
                                        <svg viewBox="0 0 24 24" width="24" height="24" className="sc-jTzLTM eWXXCS" fill="#fff">
                                            <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                                        </svg>
                                    </Link>
                                </div>
                            )}

                            <button tabindex="0" className="menubtn" type="button" onClick={toggleOffCanvas}>
                                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path d="M2 15.5v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20v-2H2z"></path>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`pxl-header-menu ${isOpen ? "show" : ""}`}>
                    <div className="pxl-header-menu-scroll">
                        {/* <div
              className="pxl-menu-close pxl-hide-xl pxl-close"
              onClick={toggleOffCanvas}
            ></div> */}
                        <div className="login-sidebar-header">
                            <div>
                                <img src={localStorage?.getItem("av")} alt="" width="80px" height="80px" />
                            </div>

                            {auth("user") ? (
                                <>
                                    <h2>{auth("user")?.fullName}</h2>
                                    <p>{auth("user")?.email}</p>
                                </>
                            ) : (
                                <>
                                    <h2 type="button" onClick={handleShow1} style={{ cursor: "pointer" }}>
                                        Login in now
                                    </h2>
                                    <p>you're not logged in</p>
                                </>
                            )}
                        </div>

                        <nav className="pxl-header-nav">
                            <ul className="">
                                {auth("user") && (
                                    <>
                                        <li className="nav-item">
                                            <Link to={`/profile/${auth("user")?.id}`} className="nav-link" onClick={toggleOffCanvas}>
                                                <span className="menu-subicon">
                                                    <i className="fa fa-user"></i>
                                                </span>
                                                My Profile
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/chat/conversation" className="nav-link" onClick={toggleOffCanvas}>
                                                <span className="menu-subicon">
                                                    <i className="fa fa-comment-dots"></i>
                                                </span>
                                                Chat
                                            </Link>
                                        </li>
                                    </>
                                )}

                                <li className="nav-item">
                                    <Link to="/" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fa fa-home"></i>
                                        </span>
                                        Home
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/pages/about-us" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon ">
                                            <i className="fas fa-info"></i>
                                        </span>
                                        About Us
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/categories" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fas fa-product-hunt"></i>
                                        </span>
                                        Party Supplies
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/vendor" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fas fa-product-hunt"></i>
                                        </span>
                                        Vendor Services
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/blog" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fa fa-newspaper"></i>
                                        </span>
                                        Blog
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link mboilehidee" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fa fa-user"></i>
                                        </span>
                                        Contact Us
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/pages/terms-and-conditions" className="nav-link" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fa fa-bookmark"></i>
                                        </span>
                                        Terms of Use
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="menu-subicon" to="/pages/privacy-policy" onClick={toggleOffCanvas}>
                                        <span className="menu-subicon">
                                            <i className="fa fa-building"></i>
                                        </span>
                                        Privacy and Policies
                                    </Link>
                                </li>

                                {auth("user") && (
                                    <li className="nav-item">
                                        <Link className="menu-subicon" to="#" onClick={() => handleLogout()}>
                                            <span className="menu-subicon">
                                                <i className="fa fa-sign-out"></i>
                                            </span>
                                            Logout
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
                {isOpen && <div className="pxl-header-menu-backdrop" onClick={toggleOffCanvas}></div>}
            </header>
            <SellYourPartyleftover isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen} />
            <FilterModalHeader show={showModal} handleClose={handleClose} />
            <LoginAuth show={showModal1} handleClose={handleClose1} />
        </>
    )
}

export default Header
