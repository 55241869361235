import React from "react"

import { Col, Container, Row } from "react-bootstrap"
import playstore from "./../../assets/img/badge_googleplay.svg"
import appstore from "./../../assets/img/badge_appstore.svg"
import myploscreen from "./../../assets/img/footerBatch.png"
import herobg from "./../../assets/img/webBanner.jpg"
import { Link, useNavigate } from "react-router-dom"
import CategoryList from "views/Component/CategoryList"
import UploadpartleftOverBtn from "views/Component/UploadpartleftOverBtn"
import { useState } from "react"
import { useEffect } from "react"
import LocationCom from "helper/my-com/LocationCom"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import GetCurrentLocation from "helper/my-com/GetCurrentLocation"
import { APICALL } from "helper/api/api"
import NoData from "helper/my-com/NoData"
import { currencySign, currencyText } from "helper/Constant"
import { defaultIMG, defaultUserIMG, imgBaseURL } from "helper/Utility"
import { Rating } from "@mui/material"

const Home = () => {
    const navigate = useNavigate()
    const herobgStyle = {
        backgroundImage: `url(${herobg})`
    }
    const [formData, setFormData] = useState({
        location: "",
        latitude: "",
        longitude: "",
        keyword: ""
    })
    const [isPopupVisible, setIsPopupVisible] = useState(false)

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible)
    }

    const handleClickOutside = (event) => {
        if (!event.target.closest(".location-box, .search-blockpopup")) {
            setIsPopupVisible(false)
        }
    }

    useEffect(() => {
        getHomeDataFun()
    }, [])

    useEffect(() => {
        if (isPopupVisible) {
            document.addEventListener("click", handleClickOutside)
        } else {
            document.removeEventListener("click", handleClickOutside)
        }

        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [isPopupVisible])
    const [loading, setLoading] = useState(false)
    const [homeData, setHomeData] = useState(null)
    const getHomeDataFun = async () => {
        setLoading(true)
        try {
            const res = await APICALL("/user/getHomepage", "post", {})
            if (res?.status) {
                setHomeData(res?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const handleSearch = () => {
        navigate(`/product-listing?location=${encodeURIComponent(formData.location)}&keyword=${formData?.keyword}&latitude=${formData?.latitude}&longitude=${formData?.longitude}`)
    }

    console.log("homeData", homeData)
    return (
        <>
            <div className="margindiv-header">
                <section className="hero-sec" style={herobgStyle}>
                    <Container className="container">
                        <Row className="justify-content-center">
                            <Col md="7" className="mx-auto px-md-5  px-xl-5">
                                <h1>The go-to platform for party leftovers and event services</h1>

                                <form>
                                    <div className="select-form-hero">
                                        <div className="search-box">
                                            <div className="location-box">
                                                <button type="button" className="btn location-button" onClick={togglePopup}>
                                                    <span className="locat-name">{formData?.location || "Choose location"}</span>
                                                    <i className="fa fa-angle-down"></i>
                                                </button>
                                            </div>

                                            <div className={`search-blockpopup ${isPopupVisible ? "" : "d-none"}`}>
                                                <div className="location-icon-iputer">
                                                    <i className="fa fa-location-dot"></i>
                                                    <LocationCom setFormData={setFormData} formData={formData} />
                                                </div>
                                                <GetCurrentLocation setFormData={setFormData} formData={formData} className={"current-location-btn"} />
                                            </div>
                                        </div>

                                        <input name="srch" onChange={(e) => setFormData({ ...formData, keyword: e.target.value })} placeholder="Search Here..." type="text" id="exampleForm.ControlInput1" className="form-control" value={formData.keyword} />
                                        <button tabIndex="0" className="search-Btn" type="button" onClick={() => handleSearch()}>
                                            {" "}
                                            Search
                                        </button>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="New_listed-pro">
                    <Container>
                        <div className="myplo-title-h2">
                            <h2>
                                <span className="shape"></span>Newly Listed Party Supplies and Party Decorations
                            </h2>
                        </div>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 g-lg-3 g-4 pt-1 ">
                            {homeData?.newAddedPartySupplies?.length > 0 ? (
                                homeData?.newAddedPartySupplies?.map((item, i) => (
                                    <div className="col">
                                        <div className="product-boxes height_normal_pro">
                                            <Link to={`/product-details/${item?._id}`} className="nn_homproductctn">
                                                <img alt="" src={item?.gallery?.length > 0 ? imgBaseURL() + item?.gallery[0] : defaultIMG} />
                                            </Link>
                                            <div className="product-boxes_inner">
                                                <h2>
                                                    <Link to={`/product-details/${item?._id}`} className="product-title">
                                                        {item?.title}
                                                    </Link>
                                                </h2>
                                                <p><i className="fa fa-location-dot"></i> {item?.location}</p>
                                                <p className="price-products">
                                                    {currencyText} {item?.price}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <NoData />
                            )}
                        </div>
                    </Container>
                </section>

                {/* Vendor Category Section */}
                <section className="New_listed-pro">
                    <Container>
                        <div className="myplo-title-h2">
                            <h2>
                                <span className="shape"></span>Vendor Services
                            </h2>
                            <Link className="see_all-all" to="/vendor">
                                {" "}
                                See All
                            </Link>
                        </div>
                        <CategoryList role={"vendorService"} />
                    </Container>
                </section>

                {/* Category Section */}
                <section className="New_listed-pro">
                    <Container>
                        <div className="myplo-title-h2">
                            <h2>
                                {" "}
                                <span className="shape"></span>Party Supplies{" "}
                            </h2>
                            <Link className="see_all-all" to="/categories">
                                {" "}
                                See All
                            </Link>
                        </div>
                        <CategoryList />
                    </Container>
                </section>

                <section className="New_listed-pro">
                    <Container>
                        <div className="myplo-title-h2">
                            <h2>
                                <span className="shape"></span>Recently Booked Vendors
                            </h2>
                            <Link className="see_all-all" to="/">
                                See All
                            </Link>
                        </div>
                        <div className="row">
                            {homeData?.recentlyBookedVendors?.length > 0 ? (
                                homeData?.recentlyBookedVendors?.map((item, i) => (
                                    <div className="col-lg-3 col-md-4 col-sm-6 mb-lg-0 mb-4">
                                        <div className="product-boxes">
                                            <Link to="">
                                                <div className="nn_homproductctn">
                                                    <img alt="" src={item?.productId?.gallery?.length > 0 ? imgBaseURL() + item?.productId?.gallery[0] : defaultIMG} />
                                                </div>
                                                <div className="product-boxes_inner">
                                                    <div className="inner-catgigstitle">
                                                        <div className="d-flex align-items-center">
                                                            <img src={item?.vendorId?.avatar ? imgBaseURL() + item?.vendorId?.avatar : defaultUserIMG} alt="" style={{ height: "35px", width: "35px", borderRadius: "100%", marginRight: "8px" }} />
                                                            <h4>{item?.productId?.title}</h4>
                                                        </div>
                                                        <h6>
                                                            {currencySign}
                                                            {item?.productId?.price}
                                                        </h6>
                                                    </div>
                                                    <p>{item?.productId?.description}</p>
                                                    <Rating name="read-only" value={item?.vendorId?.averageRating} readOnly />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <NoData />
                            )}
                        </div>
                    </Container>
                </section>
                <UploadpartleftOverBtn />
                <section className="free-app-store">
                    <Container>
                        <Row>
                            <Col md="10" className="mx-auto">
                                <Row className="align-items-end">
                                    <Col md="4" className="d-md-block d-none">
                                        <div className="myplo_footertopimg">
                                            <img alt="" src={myploscreen} className="img-fluid" />
                                        </div>
                                    </Col>
                                    <Col md="8 px-md-5">
                                        <div className="mpplo_download_appcnt">
                                            <h3>
                                                GET THE <span className="free-app">FREE</span> <span>APP</span> NOW!
                                            </h3>
                                            <div className="myplo_footerapps">
                                                <div className="myplo_googlepay">
                                                    <img alt="" src={playstore} />
                                                </div>
                                                <div className="myplo_googlepay">
                                                    <img src={appstore} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}

export default Home
