import React, { useState } from "react";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import blog from "../../../src/assets/img/blog.jpeg";
import playstore from "../../../src/assets/img/badge_googleplay.svg";
import appstore from "../../../src/assets/img/badge_appstore.svg";
import myploscreen from "../../../src/assets/img/footerBatch.png";
import { useLocation } from "react-router";
import { imgBaseURL } from "helper/Utility";
const BlogDetails = () => {
  const location = useLocation();
  const blogData = location?.state ? location?.state : null
  const [value, setValue] = useState({ comment: "" });
  const [msg, setMsg] = useState("");
  return (
    <>
      <div className="margindiv-header">
        <div className="myplo_aboutus_page">
          <Container>
            <div className="row pb-lg-5">
              <div className="col-lg-8 mx-auto">
                <div className="blog-detail-pag">
                  <img className="img-blog-details"src={blogData?.image ? imgBaseURL() + blogData?.image : blog} alt="img" />
                  <h1>{blogData?.title}</h1>
                  <div className="key-box-inner">{blogData?.content}</div>
                </div>
                <Row>
                  {/* <Col md={12}>
                    <div className="single-post-item">
                      <div className="conactvw contact-outer">
                        <h3>Post Comments</h3>

                        <ul className="comments-box">
                          <li className="comment">
                            <div className="comment-inner">
                              <div className="comment-thumb">
                                <img src={blog} alt="img" />{" "}
                              </div>
                              <div className="comment-wrap">
                                <div className="comments-meta">
                                  <h4 className="text-capitalize">scada</h4>
                                </div>
                                <div className="comment-area">
                                  <p>sdad</p>
                                </div>
                              </div>
                            </div>
                          </li>
                          <h5>There no comment to display</h5>
                        </ul>

                        <div className="section-tittle ">
                          <h3>Leave a Comment</h3>
                        </div>
                        <Card.Body className="p-0">
                          <Row className="mb-3">
                            <Col xs={12} sm={12} md={12}>
                              <Form.Group controlId="comment">
                                <Form.Control
                                  as="textarea"
                                  className="form-control-input textarea-input"
                                  value={value.comment}
                                  onChange={(e) => {
                                    setValue({
                                      ...value,
                                      comment: e.target.value,
                                    });
                                    value.comment !== "" && setMsg("");
                                  }}
                                  placeholder="Write a comment"
                                />
                              </Form.Group>
                              <div className="section-tittle px-3 mt-2">
                                <h6>{msg}</h6>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={12}>
                              <div className="sav_chang contsave btn-wrapper w-100">
                                <Button className="cmn-btn2 w-100">
                                  Submit
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </div>
          </Container>
        </div>

        <section className="free-app-store">
          <Container>
            <Row>
              <Col md="10" className="mx-auto">
                <Row className="align-items-end">
                  <Col md="4" className="d-md-block d-none">
                    <div className="myplo_footertopimg">
                      <img alt="" src={myploscreen} className="img-fluid" />
                    </div>
                  </Col>
                  <Col md="8 px-md-5">
                    <div className="mpplo_download_appcnt">
                      <h3>
                        GET THE <span className="free-app">FREE</span>{" "}
                        <span>APP</span> NOW!
                      </h3>
                      <div className="myplo_footerapps">
                        <div className="myplo_googlepay">
                          <img alt="" src={playstore} />
                        </div>
                        <div className="myplo_googlepay">
                          <img src={appstore} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default BlogDetails;
