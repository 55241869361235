import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import AppPlayStore from "views/Component/AppPlayStore";
import CategoryList from "views/Component/CategoryList";
const VendorServicesCategories = () => {
  return (
    <>
      <div className="margindiv-header">
        <div className="partysuplies-cateris">
          <Container>
            <div className="myplo-title-h2 text-center d-block">
              <h2 className="mb-3">
                <span className="shape"></span>Vendor Services
              </h2>
            </div>
            <div className="justify-content-end row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="searcbaricon text-right mb-md-5 mb-4">
                  <input type="text" className="form-control vendorOutercat" />
                  <div className="seaiconnss">
                    <svg
                      className="jss78"
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                      <path fill="none" d="M0 0h24v24H0z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <CategoryList role={"vendorService"} type={"all"} />
          </Container>
        </div>

        <AppPlayStore />

      </div>
    </>
  );
};

export default VendorServicesCategories;
