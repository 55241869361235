import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import roundcircle from "../../assets/images/succes.gif"
import productphot from "../../assets/images/gigss.png"
import stripe from "../../assets/images/stripe.png"
import { useLocation } from "react-router"
import { currencySign } from "helper/Constant"
import { defaultIMG, imgBaseURL } from "helper/Utility"

const OrderSuccess = () => {
    const locationData = useLocation()
    const orderDetails = locationData?.state ? locationData?.state : null
    return (
        <div className="order_succes_page">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6}>
                        <div className="box_order">
                            <div class="top_heading_glob text-center">
                                <img src={roundcircle} alt="sucess" />
                                <h1 class="mb-1 mt-3">Order Successfully !!</h1>
                                <p>Your order has been confirmed </p>
                            </div>

                            <div>
                                <ul class="amount_description">
                                    <li>
                                        <div class="amount_details">Payment Method</div>
                                        <div class="amount_price">
                                            <img src={stripe} height={"22px"} alt="payment_icon" />
                                        </div>
                                    </li>

                                    <li>
                                        <div class="amount_details">Order Id</div>
                                        <div class="amount_price">#{orderDetails?._id}</div>
                                    </li>
                                    <li>
                                        <div class="amount_details">Item</div>
                                        <div class="amount_price">1</div>
                                    </li>

                                    <li>
                                        <div class="amount_details">Total</div>
                                        <div class="amount_price">{currencySign}{orderDetails?.total}</div>
                                    </li>
                                </ul>

                                
                                <div class="mt-4">
                                    <h6>Ordered Item Details</h6>
                                    <div class="cart_items_details_box mt-3">
                                        <div class="cart_product_box">
                                            <div class="cart_product_image">
                                                <img src={orderDetails?.productId?.gallery?.length > 0 ? imgBaseURL() + orderDetails?.productId?.gallery[0] : defaultIMG} alt="..." />
                                            </div>
                                            <div class="cart_product_details">
                                                <div>
                                                    <h3>{orderDetails?.productId?.title}</h3>
                                                </div>
                                                <div>
                                                    <span>{currencySign}{orderDetails?.total}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <h6>Address</h6>
                                    <div class="cart_items_details_box mt-3">
                                        <div class="cart_product_box">
                                            <div class="cart_product_details">
                                                <p>{orderDetails?.addressId?.name}</p>
                                                <p>{orderDetails?.addressId?.address1}</p>
                                                <p>{orderDetails?.addressId?.address2}</p>
                                                <p>{orderDetails?.addressId?.city} - {orderDetails?.addressId?.zipcode}</p>
                                                <p>{orderDetails?.addressId?.state}</p>
                                                <p>{orderDetails?.addressId?.country}</p>
                                                <p>{orderDetails?.addressId?.phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OrderSuccess
