import React, { useState } from "react";

// import { useState } from "react";
import { Button, Container, Form, FormLabel } from "react-bootstrap";
// import OwlCarousel from "react-owl-carousel";
import delete1 from "../../assets/img/delete.png";
import pro1 from "../../assets/img/pro1.jpg";
import edit1 from "../../assets/img/edit.png";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { APICALL } from "helper/api/api";
import { defaultIMG, imgBaseURL, toastifyError, toastifySuccess } from "helper/Utility";
import { currencySign, SERVER_ERR, SOMETHING_ERR } from "helper/Constant";
import BTNLoading from "helper/my-com/BTNLoading";
import { useLocation, useNavigate } from "react-router";
import { Country, State, City } from "country-state-city";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
const OrderView = () => {
  const [activeSection, setActiveSection] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate()
  const locationData = useLocation()
  const productDetails = locationData?.state ? locationData?.state : null
  const [selectedAddress, setSelectedAddress] = useState("")
  const [modal, setModal] = useState('')
  const [stripErr, setStripErr] = useState(null);
  const defaultCountry = "US"
  useEffect(() => {
    if (!productDetails) {
      navigate(-1)
    }
  }, [])

  const [formData, setFormData] = useState({
    name: "",
    country: defaultCountry,
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState({
    'address': false,
    'submit': false,
    'shipping': false,
  })
  const stripe = useStripe();
  const elements = useElements();
  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const [addressList, setAddressList] = useState([])
  const [shipingRate, setShipingRate] = useState(null)

  const countryList = Country?.getAllCountries()?.map((country) => ({
    value: country.isoCode,
    label: country?.name
  }));
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const handleCloseModal = (type) => {
    setModal("")
    setDeleteId("")
    setFormData({
      ...formData,
      id: "",
      name: "",
      country: defaultCountry,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      phone: "",
    })
  };

  const handleDelete = async () => {
    setLoading({ ...loading, 'submit': true })
    try {
      const res = await APICALL('/user/softDeleteAddress', 'post', { id: deleteId })
      if (res?.status) {
        toastifySuccess(res?.message)
        handleCloseModal()
        getAddressFunc()
      } else {
        toastifyError(SOMETHING_ERR)
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || SERVER_ERR)
    } finally {
      setLoading({ ...loading, 'submit': false })
    }
  };

  const handleEditClick = (item) => {
    setModal("address")
    setFormData({
      ...formData,
      id: item?._id,
      name: item?.name,
      country: defaultCountry,
      address1: item?.address1,
      address2: item?.address2,
      city: item?.city,
      state: item?.state,
      zipcode: item?.zipcode,
      phone: item?.phone,
    })
    // getStateFun(item?.country)
    getStateFun(defaultCountry)
    getCityFun(item?.country, item?.state);
  }

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    getAddressFunc()
    getStateFun(defaultCountry)
  }, [])


  const getAddressFunc = async () => {
    setLoading({ ...loading, 'address': true })
    try {
      const res = await APICALL('/user/getUserAddresses', 'post', {})
      if (res?.status) {
        setAddressList(res?.data)
        setSelectedAddress(res?.data[0]?._id)
        const address = res?.data[0]
        calculateShippingRateFun(address)
      } else {
        setAddressList([])
      }
    } catch (error) {
      setAddressList([])
    } finally {
      setLoading({ ...loading, 'address': false })
    }
  }

  const calculateShippingRateFun = async (address) => {
    try {
      setLoading({ ...loading, 'shipping': true })
      const params = {
        "productId": productDetails?._id,
        "shipToAddress": {
          "name": address?.name,
          "phone": address?.phone,
          "company_name": "Example Inc.",
          "address_line1": address?.address1,
          "city_locality": address?.city,
          "state_province": address?.state,
          "postal_code": address?.zipcode,
          "country_code": address?.country,
          "address_residential_indicator": "no"
        }
      }
      const res = await APICALL('/user/calculateShippingRate', 'post', params)
      if (res?.status) {
        if (res?.data?.shippingCost) {
          setShipingRate(res?.data)
        } else {
          setShipingRate({ shippingCost: 0, serviceCode: "" })
        }
      } else {
        setShipingRate({ shippingCost: 0, serviceCode: "" })
      }
    } catch (error) {
      setShipingRate({ shippingCost: 0, serviceCode: "" })
    } finally {
      setLoading({ ...loading, 'shipping': false })
    }
  }

  // Validate form fields
  const validate = () => {
    const newErrors = {};
    if (!formData.name || formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters";
    }
    if (!formData.country || /\d/.test(formData.country)) {
      newErrors.country = "Country should not contain numbers";
    }
    if (!formData.address1) {
      newErrors.address1 = "Address 1 is required";
    }
    if (!formData.city) {
      newErrors.city = "City is required";
    }
    if (!formData.state) {
      newErrors.state = "State is required";
    }
    if (!formData.zipcode || !/^\d{5,6}$/.test(formData.zipcode)) {
      newErrors.zipcode = "Zipcode must be 5 or 6 digits";
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setFormData({ ...formData, country: e.target.value });
      getStateFun(e.target.value);
    }
    if (name === "state") {
      setFormData((prevValue) => ({ ...prevValue, state: e.target.value }));
      const countryCode = formData.country;
      getCityFun(countryCode, e.target.value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleLocationChange = async (value) => {
    setFormData((prevValue) => ({
      ...prevValue,
      address1: value
    }));
  };

  const handleLocationSelect = async (value) => {
    try {
      setFormData((prevValue) => ({
        ...prevValue,
        address1: value,
        address2: value,
      }));
    } catch (error) {
      console.error('Error selecting address', error);
    }
  };

  const handleLocationSelect2 = async (value) => {
    try {
      setFormData((prevValue) => ({
        ...prevValue,
        address2: value
      }));
    } catch (error) {
      console.error('Error selecting address', error);
    }
  };
  const handleLocationChange2 = (value) => {
    setFormData((prevValue) => ({
      ...prevValue,
      address2: value
    }));
  };

  const handleAddressSubmit = async () => {
    if (validate()) {
      setLoading({ ...loading, 'submit': true })
      try {
        const res = await APICALL('/user/addOrUpdateAddress', 'post', formData)
        if (res?.status) {
          toastifySuccess(res?.message)
          handleCloseModal()
          getAddressFunc()
        } else {
          toastifyError(SOMETHING_ERR)
        }
      } catch (error) {
        toastifyError(error?.response?.data?.message || SERVER_ERR)
      } finally {
        setLoading({ ...loading, 'submit': false })
      }
    }
  };

  const getStateFun = (country) => {
    const stateData = State?.getStatesOfCountry(country).map((state) => ({
      value: state.isoCode,
      displayValue: state.name,
    }));
    setStateList(stateData);
  };

  const getCityFun = (countryCode, stateCode) => {
    const cityData = City?.getCitiesOfState(countryCode, stateCode).map((city) => ({
      value: city.name,
      displayValue: city.name,
    }));
    setCityList(cityData);
  };

  const [location, setLocation] = useState({ latitude: null, longitude: null });

  const handleGetLocation = () => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser.");
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const address = await geocodeLatLng(latitude, longitude);
          const results = await geocodeByAddress(address);
          const latLng = await getLatLng(results[0]);

          const country = extractCountry(results[0]);

          if (country !== "US") {
            // console.error("Location access is only allowed within the United States.");
            toastifyError("Sorry, this service is only available in the United States.");
            return;
          }

          setLocation(latLng)
          extractLocationDetails(results[0]);
          setFormData((prevValues) => ({
            ...prevValues,
            address1: address,
            address2: address,
          }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      },
      (err) => {
        console.log(
          "Unable to retrieve your location. Make sure location services are enabled."
        );
      }
    );
  };

  const geocodeLatLng = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK" && results[0]) {
          resolve(results[0].formatted_address);
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  };


  const totalAmount = productDetails?.price + shipingRate?.shippingCost

  const createOrderFunc = async () => {
    setLoading({ ...loading, 'submit': true })
    const res = await APICALL("user/createPaymentInstant", "post", { amount: totalAmount });
    if (res?.status == 200) {
      const clientSecret = res?.data?.paymentIntent
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (payload?.paymentIntent?.status == "succeeded") {
        setStripErr(null);
        const params = {
          "sellerId": productDetails?.userId?._id,
          "addressId": selectedAddress,
          "productId": productDetails?._id,
          "total": totalAmount,
          "shipping" : shipingRate?.shippingCost,
          "service_code" : shipingRate?.service_code,
          "paymentMethod": "stripe",
          "paymentResponse": {
            "status": "success",
            "transactionId": payload.paymentIntent.id,
            "amount": totalAmount,
            "currency": "USD"
          }
        }

        try {
          const res = await APICALL('/user/createOrder', 'post', params)
          if (res?.status) {
            toastifySuccess(res?.message)
            navigate('/order-success', { state: res?.data })
          } else {
            toastifyError(SOMETHING_ERR)
          }
        } catch (error) {
          toastifyError(error?.response?.data?.message || SERVER_ERR)
        } finally {
          setLoading({ ...loading, 'submit': false })
        }
      } else {
        setStripErr(`Payment failed - Somthing wrong !!`);
        setLoading({ ...loading, 'submit': false })
      }
      if (payload.error) {
        setStripErr(`Payment failed ${payload.error.message}`);
        setLoading({ ...loading, 'submit': false })
      }
    }

  }

  const extractLocationDetails = (place) => {
    if (!place || !place.address_components) {
      console.error("Address components are not available.");
      return;
    }
    const addressComponents = place.address_components;
    const country = addressComponents.find((comp) =>
      comp.types.includes("country")
    )?.short_name || "";

    const state = addressComponents.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.short_name || "";

    const city = addressComponents.find((comp) =>
      comp.types.includes("locality")
    )?.short_name ||
      addressComponents.find((comp) =>
        comp.types.includes("administrative_area_level_2")
      )?.short_name || "";

    const postalCode = addressComponents.find((comp) =>
      comp.types.includes("postal_code")
    )?.long_name || "";

    getStateFun(country);
    getCityFun(country, state)
    setFormData((prevValue) => ({
      ...prevValue,
      country,
      state,
      city,
      zipcode: postalCode,
    }));

  };

  const searchOptions = {
    componentRestrictions: { country: formData.country }
  };

  const extractCountry = (result) => {
    const addressComponents = result.address_components;
    const countryComponent = addressComponents.find((component) =>
      component.types.includes("country")
    );
    return countryComponent ? countryComponent.short_name : "";
  };

  return (
    <>
      <div className="margindiv-header">
        <div className="Myplo_orderview">
          <Container>
            <h1>Review Order</h1>
            <p>Your order summary and other info</p>

            {/* Step 1: Select Address */}
            <div className="Step_one_address">
              <div className="myplo_address_flex">
                <h2 onClick={() => toggleSection(1)}>1. Select Address</h2>
                <button className="myplo_addressbtn1" onClick={() => setModal("address")}>
                  + Add Address
                </button>
              </div>
              {activeSection === 1 && (
                <div className="inner_address-empty">

                  {
                    addressList?.length > 0 ?
                      <div className="row">
                        {addressList?.map((item, index) => (
                          <div className="col-lg-4 col-md-6" key={index}>
                            <div className={`myplo_addresslist text-uppercase ${item?._id === selectedAddress ? "active" : ""}`} onClick={() => setSelectedAddress(item?._id)}>
                              <div className="cls_editdel">
                                <img src={edit1} alt="Edit" onClick={() => { handleEditClick(item) }} />
                                <img src={delete1} alt="Delete" onClick={() => { setModal("dlt_cnfm_modal"); setDeleteId(item?._id) }} />
                              </div>
                              <div className="cls_adddisplay">
                                <p>{item?.name}</p>
                                <p>{item?.address1}</p>
                                <p>{item?.address2}</p>
                                <p>{item?.city} - {item?.zipcode}</p>
                                <p>{item?.state}</p>
                                <p>{item?.country}</p>
                              </div>
                              <h3>{item?.phone}</h3>
                              <div className="text-center pt-4">
                                <button className="myplo_addressbtn1" onClick={() => { toggleSection(2); calculateShippingRateFun(item) }}> Continue</button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      :
                      <div className="Step_address-empty">
                        <h3>You haven’t added any shipping address yet.</h3>
                        <button className="myplo_addressbtn" onClick={() => setModal("address")}>+ Add Address</button>
                      </div>
                  }

                </div>
              )}
            </div>

            {/* Step 2: Order Summary */}
            <div className="sec_one_address">
              <div className="myplo_address_flex">
                <h2 onClick={() => toggleSection(2)}>2. Order Summary</h2>
                {/* <span> Order Total : <strong>{currencySign}{totalAmount}</strong></span> */}
              </div>
              {activeSection === 2 && (
                <div className="cls_ordersummery">
                  <div className="cls_orderimg">
                    <img src={productDetails?.gallery?.length > 0 ? imgBaseURL() + productDetails?.gallery[0] : defaultIMG} alt="" />
                  </div>
                  <div className="cls_ordertxt">
                    <p>{productDetails?.title}</p>
                    <p>{productDetails?.categoryId?.categoryName}</p>
                    <label>Seller: <span>{productDetails?.userId?.fullName}</span></label>
                  </div>
                  <div className="cls_orderprice">
                    <p>
                      <label>Item Fee:</label> <span>{currencySign}{productDetails?.price}</span>
                    </p>
                    <p>
                      <label>Shipping:</label>
                      {
                        loading?.shipping ? <span>Loading ...</span> :
                          <span>{currencySign}{shipingRate?.shippingCost}</span>
                      }
                    </p>
                    <p>
                      <label>Order Total:</label>
                      {
                        loading?.shipping ? <span>Loading ...</span> :
                          <span>{currencySign}{totalAmount}</span>
                      }
                    </p>
                    {
                      loading?.shipping ?
                        <button className="myplo_addressbtn1" disabled>Continue</button>
                        :
                        <button className="myplo_addressbtn1" onClick={() => toggleSection(3)}>Continue</button>
                    }
                  </div>
                </div>
              )}
            </div>

            {/* Step 3: Payment Method */}
            <div className="third_one_address">
              <div className="myplo_address_flex">
                {
                  loading?.shipping ?
                    <h2 >3. Payment Method</h2>
                    :
                    <h2 onClick={() => toggleSection(3)}>3. Payment Method</h2>
                }
              </div>
              {activeSection === 3 && (
                <div className="text-center my-md-5 my-4">
                  <button className="myplo_addressbtn1" onClick={openModal}>
                    Proceed to Pay
                  </button>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>

      {/* Delete Modal */}
      {modal === "dlt_cnfm_modal" && (
        <div className="modal  fade show delete_modal"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            inset: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-2">
              <div className="modal-body">
                <h4>Are you sure you want to delete this item?</h4>
                <div className="nn_discard_btn">
                  {
                    loading?.submit ?
                      <BTNLoading className={'btn1'} />
                      :
                      <button className="btn1" onClick={() => handleDelete(deleteId)}>Delete</button>
                  }
                  <button className="btn2" onClick={() => handleCloseModal("dlt_cnfm_modal")}> Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {modal === "address" && (
        <div className="modal  fade show Add_addres_modal"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            inset: "0px",
          }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body  p-4 pt-3">
                <div className="modal-header  pt-0 px-0 pb-2 mb-2">
                  <h3 className="modal-title">Address</h3>
                  <button className="close" onClick={() => handleCloseModal("address")}>
                    ×
                  </button>
                </div>
                {/* Name */}
                <div className="text-center mb-3">
                  <button className="myplo_addressbtn1 px-md-5" onClick={() => handleGetLocation()}>
                    <svg
                      viewBox="0 0 24 24"
                      width="22"
                      height="22"
                      fill="#fff"
                      className="sc-jTzLTM fznnpf"
                    >
                      <path
                        fill="#fff"
                        d="M12.364 2c2.204 0 4.327.865 5.915 2.463a8.4 8.4 0 0 1 2.448 5.939 8.4 8.4 0 0 1-2.448 5.942c-2.669 2.684-5.094 5.445-5.383 5.561a1.326 1.326 0 0 1-.532.095c-.19 0-.358-.024-.544-.1-.305-.123-2.767-2.937-5.372-5.556-3.264-3.282-3.264-8.6 0-11.88A8.319 8.319 0 0 1 12.364 2zm.091 11.91A3.455 3.455 0 1 0 9 10.455a3.455 3.455 0 0 0 3.455 3.455z"
                      ></path>
                    </svg>{" "}
                    Get Current Location
                  </button>
                </div>

                <Form.Group className="mb-3">
                  <FormLabel>
                    Name <span className="text-danger">*</span>
                  </FormLabel>
                  <TextField
                    name="name"
                    fullWidth
                    variant="filled"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Form.Group>

                {/* Country */}
                <Form.Group className="mb-3">
                  <Form.Label>Choose Country</Form.Label>
                  <Form.Control
                    as="select"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    disabled
                  >
                    <option value="">--SELECT--</option>
                    {countryList?.map((item, i) => (
                      <option value={item?.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Address 1 */}
                <Form.Group className="mb-3">
                  <div className="group error">
                    <PlacesAutocomplete
                      value={formData?.address1}
                      name="address1"
                      onChange={handleLocationChange}
                      onSelect={handleLocationSelect}
                      searchOptions={searchOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="location_input mb-0 w-100">
                          <Form.Label>Address 1 *</Form.Label>
                          <input className='mb-0' {...getInputProps({ placeholder: 'Start Typing' })} />
                          <div className="autocomplete-dropdown-container">
                            {loading ? <div>Loading...</div> : null}
                            {suggestions?.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? 'whitesmoke' : '#fff',
                                padding: '10px 10px',
                                cursor: 'pointer'
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <span className="errmsg">{errors.address1}</span>
                  </div>
                </Form.Group>

                {/* Address 2 */}
                <Form.Group className="mb-3">
                  <div className="group error">
                    <PlacesAutocomplete
                      value={formData?.address2}
                      name="address2"
                      onChange={handleLocationChange2}
                      onSelect={handleLocationSelect2}
                      searchOptions={searchOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="location_input mb-0 w-100">
                          <Form.Label>Address 2 *</Form.Label>
                          <input className='mb-0' {...getInputProps({ placeholder: 'Start Typing' })} />
                          <div className="autocomplete-dropdown-container">
                            {loading ? <div>Loading...</div> : null}
                            {suggestions?.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? 'whitesmoke' : '#fff',
                                padding: '10px 10px',
                                cursor: 'pointer'
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <span className="errmsg">{errors.address2}</span>
                  </div>
                </Form.Group>

                {/* State */}
                <Form.Group className="mb-3">
                  <Form.Label>Choose State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                  >
                    <option value="">--SELECT--</option>
                    {stateList?.map((item, i) => (
                      <option value={item?.value}>
                        {item.displayValue}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* City */}
                <Form.Group className="mb-3">
                  <Form.Label>Choose City</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  >
                    <option value="">--SELECT--</option>
                    {cityList?.map((item, i) => (
                      <option value={item?.value}>
                        {item.displayValue}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Zipcode */}
                <Form.Group className="mb-3">
                  <FormLabel>
                    Zipcode <span className="text-danger">*</span>
                  </FormLabel>
                  <TextField
                    name="zipcode"
                    fullWidth
                    variant="filled"
                    value={formData.zipcode}
                    onChange={handleChange}
                    error={!!errors.zipcode}
                    helperText={errors.zipcode}
                  />
                </Form.Group>
                {/* Phone */}
                <Form.Group className="mb-3">
                  <FormLabel>
                    Phone <span className="text-danger">*</span>
                  </FormLabel>
                  <TextField
                    name="phone"
                    fullWidth
                    variant="filled"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Form.Group>
                <div className="text-center">
                  {
                    loading?.submit ?
                      <BTNLoading className={"myplo_addressbtn1 mt-2"} />
                      :
                      <Button className="myplo_addressbtn1 mt-2" type="button" onClick={() => handleAddressSubmit()}>
                        Save Address
                      </Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div className="paymentpaypalwind">
          <div className="payment_wrapper">
            <div className="sm_container payment_details">
              {/* Close Button */}
              <button className="cxlosepay" onClick={closeModal}>
                <svg viewBox="0 0 24 24">
                  <path d="M12 9.988l3.822-3.822a1.423 1.423 0 0 1 2.011 2.011L14.012 12l3.821 3.822a1.42 1.42 0 0 1 0 2.011 1.42 1.42 0 0 1-2.011 0L12 14.011l-3.822 3.822a1.42 1.42 0 0 1-2.011 0 1.42 1.42 0 0 1 0-2.01L9.988 12 6.167 8.177a1.42 1.42 0 1 1 2.011-2.01L12 9.987z"></path>
                </svg>
              </button>

              {/* Modal Header */}
              <div className="payment_head d-flex justify-content-center ">
                <h3>You are paying  {currencySign}<span>{totalAmount}</span></h3>
              </div>

              {/* Modal Body */}
              <div className="payment_body">
                <div className="row">
                  <div className="col-12">
                    <CardElement className="card-stripe-element" />
                    {stripErr && (
                      <div className="stripe-error-msg mt-2 text-danger">
                        {stripErr}
                      </div>
                    )}
                    <div className="mt-4">
                      <span>Secure payments are handled by Stripe. We never even see your card details.</span>
                    </div>
                  </div>
                </div>
                <div className="pay_now">
                  {
                    loading?.submit ?
                      <BTNLoading className={"pay_now_btn"} />
                      :
                      <button type="button" className="pay_now_btn" onClick={() => createOrderFunc()}>Pay</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderView;