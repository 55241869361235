import React from 'react'

const BTNLoading = ({ className }) => {
    return (
        <>
            <button type='button' className={className}>
                <div class="spinner-border" role="status" style={{height: "18px", width: "18px"}}>
                    <span class="visually-hidden">Loading...</span>
                </div> Loading...
            </button>
        </>
    )
}

export default BTNLoading