import { APICALL } from 'helper/api/api'
import { SERVER_ERR, SOMETHING_ERR } from 'helper/Constant'
import BTNLoading from 'helper/my-com/BTNLoading'
import { toastifyError, toastifySuccess } from 'helper/Utility'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

const VerifyEmail = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState({
        "submit" : false
    })
    const verifyEmail = async () => {
        try {
            setLoading({ ...loading, 'submit': true })
            const res = await APICALL('user/verifyEmail', 'post', { token: token })
            if (res?.status) {
                toastifySuccess(res?.message)
                navigate("/")
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || SERVER_ERR)
        } finally {
            setLoading({ ...loading, 'submit': false })
        }
    }

    return (
        <>
            <div style={{ height: "100vh", width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className='content-box text-center'>
                    <div className="row justify-content-center">
                        <div className="col-md-6 p-4" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", backgroundColor: "#fff", borderRadius: '10px' }}>
                            <h3>Verify Your Email</h3>
                            <p>
                                To complete your email verification process and access your account, please verify your email address.
                                Click the button below.
                            </p>
                            {
                                loading?.submit ? <BTNLoading className={"cmn-btn2 btn btn-primary"}/> :
                            <button className='cmn-btn2 btn btn-primary' type='button' onClick={()=>verifyEmail()}>Click to verify</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyEmail