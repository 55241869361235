import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import footerbg from "../../../assets/img/footer-bg.png";
import { Col, Container, Row } from "react-bootstrap";
import SellYourPartyleftover from "views/Component/SellYourPartyleftover";
import ScrollTop from "views/Component/ScrollTop";
import { useFrontDataContext } from "helper/context/FrontContextProvider";
import { useState } from "react";
import { APICALL } from "helper/api/api";
import { imgBaseURL, toastifyError, toastifySuccess } from "helper/Utility";
import { SERVER_ERR, SOMETHING_ERR } from "helper/Constant";

const footerStyle = {
  backgroundImage: `url(${footerbg})`,
};


function Footer() {
  const { categoryList, generalSetting } = useFrontDataContext()

  const [isPaneOpen, setIsPaneOpen] = React.useState(false);
  const openSlidingPane = () => {
    setIsPaneOpen(true);
  };
  const [email, setEmail] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await APICALL('user/createSubscribe', "post", { email: email })
      if (res?.status) {
        toastifySuccess(res?.message)
        setEmail('')
      } else {
        toastifyError(SOMETHING_ERR);
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message || SERVER_ERR)
    }
  }
  return (
    <>
      <footer className="footer" style={footerStyle}>
        <Container>
          <Row>
            <Col md="3" className="mb-md-0 mb-4">
              <div className="logofooters">
                <Link to="/">
                  <img src={imgBaseURL() + generalSetting?.footerLogo} alt="Logo" />
                </Link>
                <ul className="listing-contact-info">
                  <li>
                    <a target="" href={`mailto:${generalSetting?.supportMail}`}>
                      <i className="fas fa-envelope"></i>{generalSetting?.supportMail}
                    </a>
                  </li>
                </ul>
                <ul className="social-media-links mb-5">
                  <li className="nn_fticon">
                    <a href={generalSetting?.facebookLink} title="facebook" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-facebook-f" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="nn_fticon">
                    <a href={generalSetting?.twitterLink} title="twitter" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="nn_fticon">
                    <a href={generalSetting?.instagramLink} title="instagram" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li className="nn_fticon">
                    <a href={generalSetting?.youtubeLink} title="youtube" target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-youtube" aria-hidden="true"></i>
                    </a>
                  </li>

                </ul>
                <div className="quicklink">
                  <button onClick={openSlidingPane} className="buttonfoot">
                    <i className="fa fa-camera"></i>
                    Sell Now
                  </button>
                  <a className="buttonfoot" href="/chat/conversation">
                    <i className="fa fa-message"></i>
                    Chat
                  </a>
                </div>
              </div>
            </Col>

            <Col md="3" className="mb-md-0 mb-4">
              <div className="column-cateries">
                <h4 className="footerTittle">Party Supplies by Occasion</h4>
                <ul className="listing-contact-info">
                  {categoryList?.map((item, i) => (
                    <li key={i}><Link to={`/product-listing/${item?._id}`}>{item?.categoryName}</Link></li>
                  ))}
                </ul>
              </div>
            </Col>

            <Col md="3" className="mb-md-0 mb-4 ps-md-5">
              <h4 className="footerTittle">About</h4>
              <ul className="listing-contact-info">
                <li>
                  <Link to="/contact"> Contact Us</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/pages/about-us">About Us </Link>
                </li>
                <li>
                  <Link to="/pages/privacy-policy">  Privacy Policy </Link>
                </li>
                <li>
                  <Link to="/pages/terms-and-conditions"> Terms and conditions</Link>
                </li>
                {/* <li>
                  <Link to="/pages/safety-tips"> Safety Tips </Link>
                </li> */}
              </ul>
            </Col>

            <Col md="3" className="mb-md-0 mb-4">
              <div className="footer-widgets">
                <h4 className="footerTittle">Newsletter</h4>
                <p>
                  Be the first one to yknow news, offers and events weekly in your. Unsubscribe whenever you like with one click.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-filled">
                    <input
                      className="form-control"
                      placeholder="Your Email Address"
                      type="email"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      maxLength={60}
                      required />
                    <button type="submit" className="">Submit</button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>

          <div className="copyright-myplo">
            <p>Copyright © {new Date().getFullYear()} Myplo. All Rights Reserved.</p>
          </div>
        </Container>
        <SellYourPartyleftover isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen} />
      </footer>
      <ScrollTop />
    </>

  );
}

export default Footer;
