/* eslint-disable */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import Login from 'views/auth/login';
import Blog from 'views/utilities/Blog';
import Meta from 'views/utilities/Meta';
import Feedback from 'views/admin/feedback/Feedback';
import ReportedProducts from 'views/admin/reported-products/ReportedProducts';
import OrderList from 'views/admin/orders/OrderList';
import OrderDetails from 'views/admin/orders/OrderDetails';
import NewsLetter from 'views/admin/site-manage/NewsLetter';
import ReportedUser from 'views/admin/users/ReportedUser';
import BookingList from 'views/admin/orders/BookingList';
import ShippingSize from 'views/admin/site-manage/ShippingSize';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const IntroductionDefault = Loadable(lazy(() => import('views/dashboard/Introduction')));
const Chat = Loadable(lazy(() => import('views/utilities/Chat')));
const StaticPages = Loadable(lazy(() => import('views/utilities/StaticPages')));
const EditProfile = Loadable(lazy(() => import('views/dashboard/EditProfile')));
const GeneralSetting = Loadable(lazy(() => import('views/admin/site-manage/GeneralSetting')));
const Users = Loadable(lazy(() => import('views/admin/users/Users')));
const Categories = Loadable(lazy(() => import('views/admin/categories/Categories')));
const Products = Loadable(lazy(() => import('views/admin/products/Products')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardDefault />,
        },
        {
          path: 'editprofile',
          element: <EditProfile />,
        },
        {
          path: 'users/:role',
          element: <Users />,
        },
        {
          path: 'report-users',
          element: <ReportedUser />,
        },
        {
          path: 'products/:type',
          element: <Products />,
        },
        {
          path: 'categories/:role',
          element: <Categories />,
        },
        {
          path: 'feedback',
          element: <Feedback />,
        },
        {
          path: 'orders',
          element: <OrderList />,
        },
        {
          path: 'order-details/:order_id',
          element: <OrderDetails />,
        },
        {
          path: 'bookings',
          element: <BookingList />,
        },
        {
          path: 'newsletter',
          element: <NewsLetter />,
        },

        {
          path: 'reported-products',
          element: <ReportedProducts />,
        },
        {
          path: 'shipping-size',
          element: <ShippingSize />,
        },
        {
          path: 'utils',
          children: [
            {
              path: 'pages',
              element: <StaticPages />,
            },
            {
              path: 'meta',
              element: <Meta />,
            },
            {
              path: 'blog',
              element: <Blog />,
            },
            {
              path: 'general-setting',
              element: <GeneralSetting />,
            },
          ],
        },
      ],
    },
  ],
};

export default MainRoutes;
