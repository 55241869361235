import React from 'react'
import BTNLoading from './BTNLoading'

const ConfirmDelete = ({ dltModal, setDltModal, callFun }) => {
    return (
        <>
            {dltModal?.open && (
                <div className="modal  fade show delete_modal"
                    style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)", inset: "0px", }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-2">
                            <div className="modal-body">
                                <h4>{dltModal?.msg}</h4>
                                <div className="nn_discard_btn">
                                    {dltModal?.loading ? <BTNLoading className={'btn1'} />
                                        : <button className="btn1" onClick={() => callFun()}>Yes</button>
                                    }
                                    <button className="btn2" onClick={() => setDltModal({...dltModal, 'open' : false})}> No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ConfirmDelete