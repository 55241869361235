/* eslint-disable */
import React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Grid, Menu, Tab, MenuItem, Typography, TableContainer, TablePagination, InputAdornment, Button } from "@mui/material"
import { DeleteOutline, Edit, MoreVertOutlined } from "@mui/icons-material"
import { useEffect } from "react"
import moment from "moment"
import { APICALL } from "helper/api/api"
import DeleteItem from "helper/my-com/DeleteItem"
import { SOMETHING_ERR, TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from "helper/Constant"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select from "@mui/material/Select"
import PageLoader from "helper/my-com/PageLoader"
import NoData from "helper/my-com/NoData"
import { toastifyError, toastifySuccess } from "helper/Utility"
export default function Meta() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE)
    const [isForm, setIsForm] = useState(false)
    const [loader, setLoader] = useState({
        pageLoading: false
    })
    const getDataListFun = async () => {
        setLoader({ ...loader, pageLoading: true })
        try {
            const res = await APICALL("admin/getAllPageMeta", "post", {})
            if (res?.status) {
                setData(res?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader({ ...loader, pageLoading: false })
        }
    }

    useEffect(() => {
        getDataListFun()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const [value, setValue] = useState({ pageName: "", pageUrl: "", metaTitle: "", metaDescription: "" })

    const handleChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        setValue((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const handleClose = () => {
        setValue({
            ...value,
            id: "",
            pageName: "",
            pageUrl: "",
            metaTitle: "",
            metaDescription: ""
        })
        setIsForm(!isForm)
    }

    const editClick = (row) => {
        setIsForm(true)
        setValue({
            ...value,
            id: row?._id,
            pageName: row?.pageName,
            pageUrl: row?.pageUrl,
            metaTitle: row?.metaTitle,
            metaDescription: row?.metaDescription
        })
    }

    const handleSubmit = async () => {
        try {
            const res = await APICALL("/admin/addOrUpdatePageMeta", "POST", value)
            if (res?.status) {
                toastifySuccess(res?.message)
                getDataListFun()
                handleClose()
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || "Server Error")
        }
    }
    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span>Meta
                </typography>
                <button className="addgigs" style={{ width: "unset" }} onClick={() => handleClose()}>
                    {isForm ? "Cancel" : "ADD"}
                </button>
            </div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                {!isForm ? (
                    <Box className="boxtbale">
                        {loader?.pageLoading ? (
                            <PageLoader />
                        ) : (
                            <>
                                {data.length > 0 ? (
                                    <>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>S.No</TableCell>
                                                        <TableCell>Meta title</TableCell>
                                                        <TableCell align="center">Page Name</TableCell>
                                                        <TableCell align="center">Page URL</TableCell>
                                                        <TableCell align="center">Date</TableCell>
                                                        <TableCell align="right">Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                                        <TableRow key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {i + 1}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {row.metaTitle}
                                                            </TableCell>
                                                            <TableCell align="center">{row.pageName}</TableCell>
                                                            <TableCell align="center">{row.pageUrl}</TableCell>
                                                            <TableCell align="center">{moment(row.createdAt).format("DD-MMM-YYYY")}</TableCell>
                                                            <TableCell align="right">
                                                                <div className="but-groups-action">
                                                                    <button onClick={() => editClick(row)} className="editbtn-small">
                                                                        <Edit />
                                                                    </button>
                                                                    <DeleteItem url={"/admin/deletePageMeta"} data={row} callFun={getDataListFun} />
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN} component="div" count={data?.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                                    </>
                                ) : (
                                    <NoData />
                                )}
                            </>
                        )}
                    </Box>
                ) : (
                    <Box className="boxtbale p-4">
                        <Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>
                            <Grid item md={6} justifyContent={"center"} alignItems={"center"}>
                                <Grid container spacing={1.5} className="form-inpitcustom">
                                    <Grid item xs={12} className="mb-3">
                                        <label><strong>Meta Title</strong></label>
                                        <FormControl fullWidth>
                                            <OutlinedInput placeholder="Meta Title" value={value.metaTitle} onChange={handleChange} name="metaTitle" className="form-inpitcustomfiled" />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} className="mb-3">
                                    <label><strong>Page Name</strong></label>
                                        <FormControl fullWidth>
                                            <OutlinedInput placeholder="Page Name" value={value.pageName} onChange={handleChange} name="pageName" className="form-inpitcustomfiled" />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} className="mb-3">
                                    <label><strong>Page Url</strong></label>
                                        <FormControl fullWidth>
                                            <OutlinedInput placeholder="Page URL" value={value.pageUrl} onChange={handleChange} name="pageUrl" className="form-inpitcustomfiled" />
                                        </FormControl>
                                    </Grid>

                                    <Grid item sm={12} xs={12} className="mb-3">
                                    <label><strong>Meta Description</strong></label>
                                        <FormControl fullWidth>
                                            <OutlinedInput className="form-inpitcustomfiled" onChange={handleChange} name="metaDescription" placeholder="Meta Description" multiline rows={4} id="metaDescription" value={value.metaDescription} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={12}  justifyContent={"end"} >
                                  <div>
                                  <Button type="button" className="addgigs" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                  </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
        </>
    )
}
