/* eslint-disable */
import React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"

import { Grid, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router"
import { imgBaseURL } from "helper/Utility"
import { currencySign } from "helper/Constant"
import moment from "moment"


export default function OrderDetails() {
    const navigate = useNavigate()
    const locationData = useLocation()
    const orderDetails = locationData?.state || null
    const address = orderDetails?.addressId;
    const product = orderDetails?.productId;
    const user = orderDetails?.userId;
    const seller = orderDetails?.sellerId;
    const vendor = orderDetails?.vendorId;

    console.log("orderDetails", orderDetails)

    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span>Details
                </typography>
                <button className="addgigs" style={{ width: "unset" }} onClick={() => navigate(-1)}>
                    {"Cancel"}
                </button>
            </div>
            <Box class="main_outer" sx={{ width: "100%", typography: "body1" }}>
                <Box className="boxtbale p-4">
                    <Grid container spacing={1} justifyContent={"end"}>
                        <Grid item md={8} justifyContent={"center"}>
                            <Grid container spacing={1.5} className="form-inpitcustom">
                                <Grid item xs={12} className="mb-4">
                                    <h6 style={{ color: '#9d2aed' }}>Product Details</h6>
                                    <hr />
                                    <p><strong>Product ID:</strong> {product?._id}</p>
                                    <p><strong>Title:</strong> {product?.title}</p>
                                    <p><strong>Price:</strong> {currencySign}{product?.price}</p>
                                    <p><strong>Description:</strong> {product?.description}</p>
                                    <p><strong>Weight:</strong> {product?.weight?.value || 'N/A'}</p>
                                    <p><strong>Gallery:</strong></p>
                                    {product?.gallery?.length > 0 ? (
                                        <div className="d-flex gap-2">
                                            {product.gallery.map((image, index) => (
                                                <div key={index}><img src={imgBaseURL() + image} alt={`Product Image ${index + 1}`} style={{ width: '100px', height: '100px', borderRadius: '10%' }} /></div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>No images available.</p>
                                    )}
                                </Grid>

                                <Grid item xs={12} className="mb-4">
                                    <h6 style={{ color: '#9d2aed' }}>Other Details</h6>
                                    <hr />
                                    <p><strong>Order/Booking ID:</strong> {orderDetails._id}</p>
                                    <p><strong>Created At:</strong> {new Date(orderDetails.createdAt).toLocaleString()}</p>
                                    <p><strong>Updated At:</strong> {new Date(orderDetails.updatedAt).toLocaleString()}</p>
                                    {
                                        vendor &&
                                        <>
                                        <p><strong>Booking Date:</strong> {moment(orderDetails.date).format('DD-MMM-YYYY')}</p>
                                        <p><strong>Slot:</strong> {orderDetails?.slot}</p>
                                        <p><strong>Amount:</strong> {currencySign}{orderDetails?.total}</p>
                                        </>
                                    }
                                    {
                                        seller &&
                                        <>
                                            <p><strong>Platform Fee:</strong> {currencySign}{orderDetails.platformFee}</p>
                                            <p><strong>Seller Name:</strong> {seller?.fullName}</p>
                                            <p><strong>Seller Email:</strong> {seller?.email}</p>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} justifyContent={"center"}>
                            <Grid item xs={12} className="mb-4">
                                <h6 style={{ color: '#9d2aed' }}>User Details</h6>
                                <hr />
                                <p><strong>User ID:</strong> {user?._id}</p>
                                <p><strong>Name:</strong> {user?.fullName}</p>
                                <p><strong>Email:</strong> {user?.email}</p>
                                <p><strong>Status:</strong> {user?.status ? 'Active' : 'Inactive'}</p>
                            </Grid>
                            {
                                address &&
                                <Grid item xs={12} className="mb-4">
                                    <h6 style={{ color: '#9d2aed' }}>Shipping Address</h6>
                                    <hr />
                                    <p><strong>Name:</strong> {address?.name}</p>
                                    <p><strong>Country:</strong> {address?.country}</p>
                                    <p><strong>Address Line 1:</strong> {address?.address1}</p>
                                    <p><strong>Address Line 2:</strong> {address?.address2}</p>
                                    <p><strong>City:</strong> {address?.city}</p>
                                    <p><strong>State:</strong> {address?.state}</p>
                                    <p><strong>Zip Code:</strong> {address?.zipcode}</p>
                                    <p><strong>Phone:</strong> {address?.phone}</p>
                                </Grid>
                            }

                            {
                                vendor &&
                                <Grid item xs={12} className="mb-4">
                                    <h6 style={{ color: '#9d2aed' }}>Vendor Details</h6>
                                    <hr />
                                    <p><strong>User ID:</strong> {vendor?._id}</p>
                                    <p><strong>Name:</strong> {vendor?.fullName}</p>
                                    <p><strong>Email:</strong> {vendor?.email}</p>
                                    <p><strong>Status:</strong> {vendor?.status ? 'Active' : 'Inactive'}</p>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
