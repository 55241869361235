import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import loginimg from "../../assets/img/loginimg.png";
import groupuser from "../../assets/img/groupuser.svg";
import googleimg from "../../assets/img/googleimg.png";
import fbimg from "../../assets/img/fbimg.png";
import groupvendor from "../../assets/img/groupvendo.svg";
import { Link, useNavigate } from "react-router-dom";
import { APICALL } from "helper/api/api";
import { defaultUserIMG, encryptLocalStorageData, imgBaseURL, RedirectVendorURI, toastifyError, toastifySuccess } from "helper/Utility";
import BTNLoading from "helper/my-com/BTNLoading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputGroup } from "react-bootstrap";
import { useFrontDataContext } from "helper/context/FrontContextProvider";

const LoginAuth = ({ show, handleClose }) => {
  const { getUserInfoFun, userInfo } = useFrontDataContext()
  const navigate = useNavigate()
  const [visibleStep, setVisibleStep] = useState("firstStep");
  const [loading, setLoading] = useState(false)
  const handlePageChange = (step) => {
    setVisibleStep(step)
  }
  const [otp, setOtp] = useState("");
  const generateOtp = () => {
    const randomOtp = Math.floor(1000 + Math.random() * 9000);
    setOtp(randomOtp.toString());
  };
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
    role: "User",
  });


  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [loginErrors, setLoginErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName.trim()) newErrors.fullName = "Full Name is required.";

    if (!formData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Enter a valid email address.";

    if (!formData.password.trim()) newErrors.password = "Password is required.";
    else if (formData.password.length < 8) newErrors.password = "Password must be at least 6 characters long.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const sendOTP = async (e) => {
    e.preventDefault();
    const email = formData?.email;
    if (!email) {
      toastifyError("Email is required!");
      return;
    }
    if (!validateEmail(email)) {
      toastifyError("Invalid email format!");
      return;
    }
    setLoading(true)
    try {
      const res = await APICALL('/user/requestOtp', "POST", { email })
      if (res.status) {
        setVisibleStep("otpScreen")
      } else {
        toastifyError("OTP Send failed.")
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true)
      try {
        const res = await APICALL('/user/userSignup', "POST", formData)
        if (res.status) {
          const dataParam = {
            token: res?.token,
            fullName: res?.user?.fullName,
            email: res?.user?.email,
            id: res?.user?._id,
            role: res?.user?.role,
          };
          toastifySuccess("Registration Successfully !!")
          handleReset()
          if (dataParam?.role == "Vendor") {
            window.location.href = `${RedirectVendorURI()}?token=${dataParam?.token}&email=${dataParam?.email}&name=${dataParam.fullName}`;
          } else {
            localStorage.setItem("av", res?.data?.avatar ? imgBaseURL() + res?.data?.avatar : defaultUserIMG)
            encryptLocalStorageData("web-secret", dataParam, "DoNotTryToAccess");
            navigate('/')
            getUserInfoFun()
            handleClose()
          }
        } else {
          toastifyError("Registration failed.")
        }
      } catch (error) {
        toastifyError(error?.response?.data?.message)
      } finally {
        setLoading(false)
      }
    }
  };

  const loginValidate = () => {
    const newErrors = {};

    if (!loginFormData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(loginFormData.email)) newErrors.email = "Enter a valid email address.";

    if (!loginFormData.password.trim()) newErrors.password = "Password is required.";
    else if (loginFormData.password.length < 6) newErrors.password = "Password must be at least 6 characters long.";

    setLoginErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loginValidate()) return;
    setLoading(true)
    try {
      const res = await APICALL('/user/userLogin', "POST", loginFormData)
      if (res.status) {
        const dataParam = {
          token: res?.token,
          fullName: res?.data?.fullName,
          email: res?.data?.email,
          id: res?.data?._id,
          role: res?.data?.role,
          status: res?.data?.status,
        };
        toastifySuccess(res?.message)
        handleReset()
        if (dataParam?.role == "Vendor") {
          handleClose()
          window.location.href = `${RedirectVendorURI()}?token=${dataParam?.token}&email=${dataParam?.email}&name=${dataParam.fullName}`;
        } else {
          localStorage.setItem("av", res?.data?.avatar ? imgBaseURL() + res?.data?.avatar : defaultUserIMG)
          encryptLocalStorageData("web-secret", dataParam, "DoNotTryToAccess");
          navigate('/')
          getUserInfoFun()
          handleClose()
        }
      } else {
        toastifyError("Login failed.")
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  };

  const handleReset = () => {
    setFormData({
      ...formData,
      fullName: "",
      mobileNumber: "",
      email: "",
      otp: "",
      password: "",
      confirmPassword: "",
      role: "User",
    })
  }

  const validateFields = (formData) => {
    let isValid = true;
    if (!formData.otp) {
      toastifyError("OTP is required!");
      isValid = false;
    } else if (!/^\d{6}$/.test(formData.otp)) {
      toastifyError("OTP must be a 6-digit number!");
      isValid = false;
    }

    if (!formData.password) {
      toastifyError("Password is required!");
      isValid = false;
    } else if (formData.password.length < 8) {
      toastifyError("Password must be at least 8 characters long!");
      isValid = false;
    }

    if (!formData.confirmPassword) {
      toastifyError("Confirm Password is required!");
      isValid = false;
    } else if (formData.confirmPassword !== formData.password) {
      toastifyError("Passwords do not match!");
      isValid = false;
    }
    return isValid;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (validateFields(formData)) {
      setLoading(true)
      try {
        const res = await APICALL('/user/changePassword', "POST", formData)
        if (res.status) {
          toastifySuccess("Password changed successfully, Please Login !!")
          setVisibleStep("fourthStep")
          handleReset()
        } else {
          toastifyError("OTP Send failed.")
        }
      } catch (error) {
        toastifyError(error?.response?.data?.message)
      } finally {
        setLoading(false)
      }
    }
  };


  const [showPassword, setShowPassword] = useState(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="loginModalMyplo_outter"
      backdrop="static" // Prevents backdrop click from closing
      keyboard={false} // Prevents 'Escape' key from closing
    >
      <Modal.Body className="p-0">
        <div className="loginModalMyplo">
          <Button
            variant="link"
            className="closicon-login"
            onClick={handleClose}
          >
            <i className="fa fa-times"></i>
          </Button>
          <div className="loginModalMyplo_left">
            <div className="loginModalMyplo_inner">
              <h2>Log in to unlock these features</h2>
              <ul>
                <li>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <path d="M6.892 5.571A3.468 3.468 0 0 1 10.262 3h3.477a3.473 3.473 0 0 1 3.369 2.571h1.416c1.92 0 3.476 1.536 3.476 3.43v8.57C22 19.464 20.442 21 18.524 21H5.476C3.556 21 2 19.465 2 17.571v-8.57c0-1.893 1.558-3.43 3.476-3.43h1.416zm9.456 7.715C16.348 10.919 14.4 9 12 9s-4.348 1.919-4.348 4.286c0 2.367 1.947 4.285 4.348 4.285s4.348-1.918 4.348-4.285zm-6.957 0c0-1.42 1.168-2.572 2.609-2.572 1.44 0 2.609 1.152 2.609 2.572 0 1.42-1.168 2.571-2.609 2.571-1.44 0-2.609-1.151-2.609-2.571z"></path>
                  </svg>
                  Post items for sale
                </li>
                <li>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <path d="M7.249 21.204v-1.902c0-.58-.47-1.05-1.05-1.05A4.2 4.2 0 0 1 2 14.053v-5.86A4.194 4.194 0 0 1 6.193 4h11.734a4.193 4.193 0 0 1 4.193 4.193v5.866a4.193 4.193 0 0 1-4.193 4.193h-5.013c-.444 0-.87.177-1.185.49l-3.05 3.048c-.525.526-1.424.158-1.43-.586zm.617-8.828a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm8.383 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512zm-4.191 0a1.255 1.255 0 1 0 0-2.512 1.256 1.256 0 1 0 0 2.512z"></path>
                  </svg>
                  Message buyers and sellers
                </li>
                <li>
                  <svg viewBox="0 0 24 24" width="24" height="24">
                    <path d="M16.224 5c-1.504 0-2.89.676-3.802 1.854L12 7.398l-.421-.544A4.772 4.772 0 0 0 7.776 5C5.143 5 3 7.106 3 9.695c0 5.282 6.47 11.125 9.011 11.125 2.542 0 8.99-5.445 8.99-11.125C21 7.105 18.857 5 16.223 5z"></path>
                  </svg>
                  View your favorite
                </li>
              </ul>
            </div>
          </div>
          <div className="loginModalMyplo_Right">
            {visibleStep === "firstStep" && (
              <div className="firstep_login">
                <img src={loginimg} alt="" />
                <h2>
                  Buy and sell quickly and safely. It’s time to go with Plo!
                </h2>
                <h3>
                  <span> OR USE YOUR EMAIL</span>
                </h3>
                <ul className="login_login">
                  <li>
                    <button type="button" onClick={() => handlePageChange("thirdStep")}>
                      <span className="signuinbtn">Sign Up</span>
                    </button>
                  </li>
                  <li className="rtlborder">
                    <button type="button" onClick={() => handlePageChange("secondStep")}>
                      <span className="signuinbtn">Log In</span>
                    </button>
                  </li>
                </ul>
                <p className="footer-trms">
                  By clicking on Sign up, you agree to the myplo.com{" "}
                  <strong>
                    <Link to="/pages/terms-and-conditions" onClick={handleClose}>
                      Terms and Conditions
                    </Link>
                  </strong>{" "}
                  and{" "}
                  <strong>
                    <Link to="/pages/privacy-policy" onClick={handleClose}>Privacy policy</Link>
                  </strong>
                </p>
              </div>
            )}

            {visibleStep === "secondStep" && (
              <div className="secstep_login">
                <div className="login-heading">
                  <button
                    type="button"
                    onClick={() => handlePageChange("firstStep")}
                    className="back-btn"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Log In
                  </h2>
                </div>

                <div align="center" className="registerants">
                  <button type="button" className="loginasvendor" onClick={() => handlePageChange("fourthStep")}>
                    <img src={groupuser} alt="user" />
                    <div className="titles-vendor">Log In As a User</div>
                  </button>
                  <h3 className="mt-3 mb-3">
                    <span> OR</span>
                  </h3>
                  <button type="button" className="loginasvendor" onClick={() => { handlePageChange("fourthStep"); setFormData({ ...formData, 'role': "Vendor" }) }}>
                    <img src={groupvendor} alt="vendor" />
                    <div className="titles-vendor">Log In As a Vendor</div>
                  </button>
                </div>
              </div>
            )}

            {visibleStep === "thirdStep" && (
              <div className="thirdstep_login">
                <div className="login-heading">
                  <button
                    type="button"
                    onClick={() => handlePageChange("firstStep")}
                    className="back-btn"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Create a new account
                  </h2>
                </div>

                <div align="center" className="registerants">
                  <button type="button"
                    className="loginasvendor"
                    onClick={() => { handlePageChange("fifthStep"); generateOtp() }}
                  >
                    <img src={groupuser} alt="user" />
                    <div className="titles-vendor">As User</div>
                  </button>
                  <h3 className="mt-3 mb-3">
                    <span> OR</span>
                  </h3>
                  <button type="button"
                    className="loginasvendor"
                    onClick={() => { handlePageChange("fifthStep"); setFormData({ ...formData, 'role': "Vendor" }); generateOtp() }}
                  >
                    <img src={groupvendor} alt="vendor" />
                    <div className="titles-vendor">As  Vendor</div>
                  </button>
                </div>
              </div>
            )}

            {visibleStep === "fourthStep" && (
              <div className="fourstep_login">
                <div className="login-heading">
                  <button
                    type="button"
                    onClick={() => handlePageChange("firstStep")}
                    className="back-btn"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Log In
                  </h2>
                </div>
                <Form className="pt-3" onSubmit={handleLogin}>

                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={loginFormData.email}
                      onChange={handleLoginChange}
                      isInvalid={!!loginErrors.email}
                    />
                    <Form.Control.Feedback type="invalid">{loginErrors.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formPassword" className="mb-3">
                    <Form.Label>
                      Password<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="input-group">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={loginFormData.password}
                      onChange={handleLoginChange}
                      isInvalid={!!loginErrors.password}
                    />
                    <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputGroup.Text>
                    </div>

                    <Form.Control.Feedback type="invalid">{loginErrors.password}</Form.Control.Feedback>
                  </Form.Group>
                  {
                    loading ? <BTNLoading className={"cmn-btn-auth"} /> :
                      <Button type="submit" className="cmn-btn-auth">Login</Button>
                  }

                  <div className="socialLoginouter d-flex justify-content-center">
                    <div className="google login-google">
                      <Link to={'#'}>
                        <img src={googleimg} alt="" height="30px" />
                      </Link>
                    </div>
                    {/* <div className="facebook login-facebook">
                      <Link to={'#'}>
                        <img src={fbimg} height="30px" alt="" />
                      </Link>
                    </div> */}
                  </div>
                  <p className="forget_Account" style={{ cursor: "pointer" }} onClick={() => handlePageChange("forgot")}>Forgot your Password</p>
                  {/* <p className="alredy_Account">Don't Have an Account</p> */}
                </Form>
              </div>
            )}

            {visibleStep === "fifthStep" && (
              <div className="fivestep_login">
                <div className="login-heading">
                  <button
                    type="button"
                    onClick={() => handlePageChange("firstStep")}
                    className="back-btn"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Create a new account
                  </h2>
                </div>

                <Form className="pt-3" onSubmit={handleSubmit}>

                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      isInvalid={!!errors.fullName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fullName}</Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>

                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formPassword" className="mb-3">
                    <Form.Label>
                      Password<span className="text-danger">*</span>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <InputGroup.Text onClick={() => setShowPassword(!showPassword)} style={{ cursor: "pointer" }}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <p className="footer-trms text-start">
                    {" "}
                    By clicking on Sign up, you agree to the myplo.com{" "}
                    <strong>
                      <Link to="/pages/terms-and-conditions" onClick={handleClose}> Terms and Conditions </Link>
                    </strong>{" "}
                    and{" "}
                    <strong>
                      <Link to="/pages/privacy-policy" onClick={handleClose}>Privacy policy</Link>
                    </strong>
                  </p>
                  {
                    loading ? <BTNLoading className={"cmn-btn-auth"} /> :
                      <Button type="submit" className="cmn-btn-auth">SIGN UP</Button>
                  }
                  <div className="socialLoginouter d-flex justify-content-center">
                    <div className="google login-google">
                      <Link to={'#'}>
                        <img src={googleimg} alt="" height="30px" />
                      </Link>
                    </div>
                    {/* <div className="facebook login-facebook">
                      <Link to={'#'}>
                        <img src={fbimg} height="30px" alt="" />
                      </Link>
                    </div> */}
                  </div>
                  <p className="forget_Account" style={{ cursor: "pointer" }} onClick={() => handlePageChange("forgot")}>Forgot your Password</p>
                  {/* <p className="alredy_Account">Don't Have an Account</p> */}
                </Form>
              </div>
            )}

            {visibleStep === "otpScreen" && (
              <div className="fivestep_login">
                <div className="login-heading">
                  <button type="button" onClick={() => handlePageChange("fifthStep")} className="back-btn">
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Set your new password
                  </h2>
                </div>
                <Form className="pt-3" onSubmit={handleChangePassword}>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>
                      OTP<span className="text-danger"> * <span style={{ fontSize: "14px" }}>Check your mail for OTP</span>.</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="otp"
                      maxLength={6}
                      value={formData.otp}
                      onChange={handleChange}
                      isInvalid={!!errors.otp}
                    />
                    <Form.Control.Feedback type="invalid">{errors.otp}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>
                      New password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="password"
                      maxLength={30}
                      value={formData.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                  </Form.Group>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>
                      Confirm password<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="confirmPassword"
                      maxLength={30}
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                  </Form.Group>
                  {
                    loading ? <BTNLoading className={"cmn-btn-auth"} /> :
                      <Button type="submit" className="cmn-btn-auth">Change</Button>
                  }
                </Form>
              </div>
            )}

            {visibleStep === "forgot" && (
              <div className="fivestep_login">
                <div className="login-heading">
                  <button type="button" onClick={() => handlePageChange("secondStep")} className="back-btn">
                    <i className="fa fa-arrow-left"></i>
                  </button>
                  <h2>
                    <span className="shape"></span>Enter your email
                  </h2>
                </div>
                <Form className="pt-3" onSubmit={sendOTP}>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      maxLength={40}
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                  {
                    loading ? <BTNLoading className={"cmn-btn-auth"} /> :
                      <Button type="submit" className="cmn-btn-auth">Send OTP</Button>
                  }
                </Form>
              </div>
            )}

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginAuth;