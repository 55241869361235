/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Menu, Tab, MenuItem, Typography, TableContainer, TablePagination } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DeleteOutline, Edit, MoreVertOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import moment from 'moment';
import { APICALL } from 'helper/api/api';
import DeleteItem from 'helper/my-com/DeleteItem';
import { TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from 'helper/Constant';
import { imgBaseURL } from 'helper/Utility';
import NoData from 'helper/my-com/NoData';
import PageLoader from 'helper/my-com/PageLoader';
import { toastifyError, toastifySuccess } from 'helper/Utility';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import CKEditorCom from 'helper/my-com/CKEditorCom';
import ImgTable from 'helper/my-com/ImgTable';
import { defaultIMG } from 'helper/Utility';
import HTMLContent from 'helper/my-com/HTMLContent';
export default function Blog() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const [isForm, setIsForm] = useState(false)
    const [loader, setLoader] = useState({
        'pageLoading': false
    })
    const getDataListFun = async () => {
        setLoader({ ...loader, 'pageLoading': true })
        try {
            const res = await APICALL('admin/getActiveBlogs', "post", {})
            if (res?.status) {
                setData(res?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader({ ...loader, 'pageLoading': false })
        }
    }

    useEffect(() => {
        getDataListFun()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [previewImage, setPreviewImage] = useState(null);

    const [value, setValue] = useState({ title: '', image: '', content: '' });
    const inputRef = React.useRef(null);
    const handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setValue((prev) => ({
            ...prev,
            [key]: value
        }));
    }
    const handleClose = () => {
        setValue({
            ...value,
            title: '', image: '', content: ''
        })
        setIsForm(!isForm)
    }

    const editClick = (row) => {
        setIsForm(true)
        setValue({
            ...value, id: row?._id,
            title: row?.title, image: imgBaseURL() + row?.image, content: row?.content
        })
    }
    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            if (value?.id) {
                formData.append('id', value.id);
            }
            formData.append('title', value.title);
            formData.append('image', value.image);
            formData.append('content', value.content);
            const res = await APICALL('/admin/addOrUpdateBlog', "POST", formData)
            if (res?.status) {
                toastifySuccess(res?.message)
                getDataListFun()
                handleClose()
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || "Server Error")
        }
    }

    const handleEditorChange = (value) => {
        setValue((prevValues) => {
            return { ...prevValues, ["content"]: value };
        });
    }

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0];
        setValue((prev) => ({ ...prev, ["image"]: imageFile }));
        setPreviewImage(imageFile);
    };


    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span>Blogs
                </typography>
                <button className='addgigs' style={{ width: "unset" }} onClick={() => handleClose()}>
                    {isForm ? "Cancel" : "ADD"}
                </button>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                {
                    !isForm ?
                        <Box className="boxtbale">
                            {
                                loader?.pageLoading ?
                                    <PageLoader />
                                    :
                                    <>
                                        {
                                            data.length > 0 ?
                                                <>
                                                    <TableContainer>
                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>S.No</TableCell>
                                                                    <TableCell align="left">Image</TableCell>
                                                                    <TableCell align="left">Title</TableCell>
                                                                    <TableCell align="left">Content</TableCell>
                                                                    <TableCell align="left">Date</TableCell>
                                                                    <TableCell align="right">Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    .map((row, i) => (
                                                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                                            <TableCell align="left">
                                                                               <ImgTable image={row?.image}/>
                                                                            </TableCell>
                                                                            <TableCell align="left">{row.title}</TableCell>
                                                                            <TableCell align="left"><HTMLContent data={row.content}/></TableCell>
                                                                            <TableCell align="left">{moment(row.createdAt).format('DD-MMM-YYYY')}</TableCell>
                                                                            <TableCell align="right">
                                                                                <div className="but-groups-action">
                                                                                    <button onClick={() => editClick(row)} className="editbtn-small"><Edit /></button>
                                                                                    <DeleteItem url={'/admin/deleteBlog'} data={row} callFun={getDataListFun} />
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                        component="div"
                                                        count={data?.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />

                                                </>
                                                :
                                                <NoData />
                                        }
                                    </>
                            }
                        </Box>
                        :
                        <Box className="boxtbale p-4">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container spacing={1.5} className="form-inpitcustom">

                                        <Grid item xs={12} textAlign={'center'}>
                                            <div className="data">
                                                {previewImage != null ?
                                                    <img
                                                        src={URL.createObjectURL(previewImage)}
                                                        alt="Preview"
                                                        onClick={() => inputRef.current.click()}
                                                        height={150}
                                                        width={150}
                                                        style={{ borderRadius: "50%", objectFit: "cover" }}
                                                    /> :
                                                    <img
                                                        src={value?.image ? value?.image : defaultIMG}
                                                        onClick={() => inputRef.current.click()}
                                                        height={150}
                                                        width={150}
                                                        style={{ borderRadius: '50%', objectFit: "cover" }}
                                                    />
                                                }

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                    ref={inputRef}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} className='mb-3'>
                                        <label><strong>Blog Title</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput placeholder="Title" value={value.title} onChange={handleChange} name="title" className="form-inpitcustomfiled" />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} xs={12} className='mb-3'>
                                        <label><strong>Description</strong></label>
                                        <CKEditorCom
                                            ckValue={value.content}
                                            handleEditorChange={handleEditorChange}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} sm={12} alignItems={'end'} justifyContent={'end'} textAlign={'center'}
                                        margin={'20px auto 0px'}
                                    >
                                     <div>
                                     <button type="button" className="addgigs" onClick={handleSubmit}>Save</button>
                                     </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                }

            </Box>
        </>
    );
}
