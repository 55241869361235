/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Grid, Menu, Tab, MenuItem, Typography, TableContainer, TablePagination, InputAdornment, Button, InputLabel } from '@mui/material';
import { DeleteOutline, Edit, MoreVertOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import moment from 'moment';
import { APICALL } from 'helper/api/api';
import DeleteItem from 'helper/my-com/DeleteItem';
import { SOMETHING_ERR, TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from 'helper/Constant';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import PageLoader from 'helper/my-com/PageLoader';
import NoData from 'helper/my-com/NoData';
import { defaultIMG, imgBaseURL, toastifyError, toastifySuccess } from 'helper/Utility';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ImgTable from 'helper/my-com/ImgTable';
import { useParams } from 'react-router';
import BTNLoading from 'helper/my-com/BTNLoading';
export default function ShippingSize() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const [isForm, setIsForm] = useState(false)
    const [loader, setLoader] = useState({
        'pageLoading': false,
        'submit': false,
    })
    const { role } = useParams()
    const getDataListFun = async () => {
        setLoader({ ...loader, 'pageLoading': true })
        try {
            const res = await APICALL('admin/getShippingSizes', "post", {})
            if (res?.status) {
                setData(res?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader({ ...loader, 'pageLoading': false })
        }
    }

    useEffect(() => {
        getDataListFun()
    }, [role])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [previewImage, setPreviewImage] = useState(null);
    const inputRef = React.useRef(null);
    const [value, setValue] = useState({
        name: "",
        length: "",
        image: "",
        width: "",
        height: "",
        dimensionUnit: "",
        weightUnit: "",
        weightValue: "",
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setValue((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleClose = () => {
        setValue({
            ...value,
            id: "",
            name: "",
            length: "",
            image: "",
            width: "",
            height: "",
            dimensionUnit: "",
            weightUnit: "",
            weightValue: "",
        })
        setPreviewImage(null)
        setIsForm(!isForm)
    }

    const editClick = (row) => {
        setIsForm(true)
        setValue({
            ...value, id: row?._id,
            name: row?.name, length: row?.length, image: imgBaseURL() + row?.image, width: row?.width,
            height: row?.height,
            dimensionUnit: row?.dimensionUnit,
            weightUnit: row?.weightUnit,
            weightValue: row?.weightValue,
        })
    }

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0];
        setValue((prev) => ({ ...prev, ["image"]: imageFile }));
        setPreviewImage(imageFile);
    };

    const handleSubmit = async () => {
        setLoader({ ...loader, 'submit': true })
        try {
            const formData = new FormData();
            if (value?.id) {
                formData.append('id', value.id);
            }
            formData.append('name', value.name);
            formData.append('length', value.length);
            formData.append('width', value?.width);
            formData.append('image', value.image);
            formData.append('height', value.height);
            formData.append('dimensionUnit', value.dimensionUnit);
            formData.append('weightUnit', value.weightUnit);
            formData.append('weightValue', value.weightValue);
            const res = await APICALL('/admin/addOrUpdateShippingSize', "POST", formData)
            if (res?.status) {
                toastifySuccess(res?.message)
                getDataListFun()
                handleClose()
            } else {
                toastifyError(SOMETHING_ERR)
            }
        } catch (error) {
            console.log(error)
            toastifyError(error?.response?.data?.message || "Server Error")
        } finally {
            setLoader({ ...loader, 'submit': false })
        }
    }

    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span>Shipping Size
                </typography>
                <button className='addgigs' style={{ width: "unset" }} onClick={() => handleClose()}>
                    {isForm ? "Cancel" : "ADD"}
                </button>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>

                {
                    !isForm ?
                        <Box className="boxtbale">
                            {
                                loader?.pageLoading ?
                                    <PageLoader />
                                    :
                                    <>
                                        {
                                            data.length > 0 ?
                                                <>
                                                    <TableContainer>
                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>S.No</TableCell>
                                                                    <TableCell>Image</TableCell>
                                                                    <TableCell>Size</TableCell>
                                                                    <TableCell align="center">Date</TableCell>
                                                                    <TableCell align="right">Action</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                    .map((row, i) => (
                                                                        <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                            <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                                            <TableCell component="th" scope="row"><ImgTable image={row.image} /> </TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                <strong>{row?.name}</strong> <br /> (Approx {row?.length}{row?.dimensionUnit == "inch" ? '″' : "cm"} x {row?.width}{row?.dimensionUnit == "inch" ? '″' : "cm"} x {row?.height}{row?.dimensionUnit == "inch" ? '″' : "cm"}, Weight: {row?.weightValue} {row?.weightUnit})
                                                                            </TableCell>
                                                                            <TableCell align="center">{moment(row.createdAt).format('DD-MMM-YYYY')}</TableCell>
                                                                            <TableCell align="right">
                                                                                <div className="but-groups-action">
                                                                                    <button onClick={() => editClick(row)} className="editbtn-small"><Edit /></button>
                                                                                    <DeleteItem url={'/admin/deleteShippingSize'} data={row} callFun={getDataListFun} />
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                        component="div"
                                                        count={data?.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />

                                                </>
                                                :
                                                <NoData />
                                        }
                                    </>
                            }
                        </Box>
                        :
                        <Box className="boxtbale p-4">
                            <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                                <Grid item md={6} justifyContent={'center'} alignItems={'center'}>
                                    <Grid container spacing={1.5} className="form-inpitcustom">

                                        <Grid item xs={12} textAlign={'center'} className='mb-3'>
                                            <div className="data">
                                                {previewImage != null ?
                                                    <img
                                                        src={URL.createObjectURL(previewImage)}
                                                        alt="Preview"
                                                        onClick={() => inputRef.current.click()}
                                                        height={150}
                                                        width={150}
                                                        style={{ borderRadius: "50%", objectFit: "cover" }}
                                                    /> :
                                                    <img
                                                        src={value?.image ? value?.image : defaultIMG}
                                                        onClick={() => inputRef.current.click()}
                                                        height={150}
                                                        width={150}
                                                        style={{ borderRadius: '50%', objectFit: "cover" }}
                                                    />
                                                }

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                    ref={inputRef}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} className='mb-3'>
                                            <label><strong>Name</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="Name"
                                                    value={value.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    className="form-inpitcustomfiled"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={4} className='mb-3'>
                                            <label className='text-capitalize'><strong>length</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="length"
                                                    value={value.length}
                                                    onChange={handleChange}
                                                    name="length"
                                                    className="form-inpitcustomfiled"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={4} className='mb-3'>
                                            <label className='text-capitalize'><strong>width</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="width"
                                                    value={value.width}
                                                    onChange={handleChange}
                                                    name="width"
                                                    className="form-inpitcustomfiled"
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={4} className='mb-3'>
                                            <label className='text-capitalize'><strong>height</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="height"
                                                    value={value.height}
                                                    onChange={handleChange}
                                                    name="height"
                                                    className="form-inpitcustomfiled"
                                                />
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12} sm={6} md={6} className="mb-4">
                                            <FormControl fullWidth>
                                                <InputLabel id="selling-status-label">Dimension Unit</InputLabel>
                                                <Select labelId="selling-status-label" value={value.dimensionUnit} label="Dimension Unit" name="dimensionUnit" onChange={handleChange}>
                                                    <MenuItem value="inch">Inch</MenuItem>
                                                    <MenuItem value="centimeter">Centimeter</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} className="mb-4">
                                            <FormControl fullWidth>
                                                <InputLabel id="selling-status-label">Weight Unit</InputLabel>
                                                <Select labelId="selling-status-label" value={value.weightUnit} label="Weight Unit" name="weightUnit" onChange={handleChange}>
                                                    <MenuItem value="ounce">Ounce</MenuItem>
                                                    <MenuItem value="pound">Pound</MenuItem>
                                                    <MenuItem value="gram">Gram</MenuItem>
                                                    <MenuItem value="kilogram">Kilogram</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6} sm={4} md={4} className='mb-3'>
                                            <label className='text-capitalize'><strong>Weight Value</strong></label>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="weightValue"
                                                    value={value.weightValue}
                                                    onChange={handleChange}
                                                    name="weightValue"
                                                    className="form-inpitcustomfiled"
                                                />
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                    <Grid container xs={12} sm={12} justifyContent="end">
                                        <div className='text-end d-block'>
                                            {
                                                loader?.submit ? <BTNLoading className={'addgigs'} /> :
                                                    <Button type="button" className="addgigs" onClick={handleSubmit}>Save</Button>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                }

            </Box>
        </>
    );
}
