import React from 'react'
import { useState } from 'react';
import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    FormLabel,
    Nav,
    Row,
    Tab,
    TabContainer,
    Table,
} from "react-bootstrap";
import { TextField } from "@mui/material";
import { useEffect } from 'react';
import BTNLoading from 'helper/my-com/BTNLoading';
import { auth, stripeRedirectURI, toastifyError, toastifySuccess } from 'helper/Utility';
import { SERVER_ERR, SOMETHING_ERR } from 'helper/Constant';
import { APICALL } from 'helper/api/api';
import DeleteItem from 'helper/my-com/DeleteItem';
import { useFrontDataContext } from 'helper/context/FrontContextProvider';
import verfication from '../../../../src/assets/images/identity-verification.png'
import { Country, State, City } from "country-state-city";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
const PayoutMethod = ({ dashboardDetails }) => {
    const { userInfo } = useFrontDataContext()

    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => setShowModal(true);
    const [payoutDetails, setPayoutDetails] = useState(dashboardDetails?.payout)

    const [formData, setFormData] = useState({
        dob: "",
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
        payoutMethod: "stripe",
        paypalEmail: ""
    });
    const countryList = Country?.getAllCountries()?.map((country) => ({
        value: country.isoCode,
        label: country?.name
    }));
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        'address': false,
        'submit': false,
        'stripeConnect': false,
    })

    const handleCloseModal = (type) => {
        setShowModal(false)
        setFormData({
            ...formData,
            id: "",
            paypalEmail: "",
            country: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            postalCode: "",
            phone: "",
        })
    };


    const handleEditClick = (item) => {
        setShowModal(true)
        setFormData({
            ...formData,
            id: item?._id,
            paypalEmail: item?.paypalEmail,
            country: item?.country,
            address1: item?.address1,
            address2: item?.address2,
            city: item?.city,
            state: item?.state,
            postalCode: item?.postalCode,
        })
        getStateFun(item?.country)
        getCityFun(item?.country, item?.state);
    }

    const afterDeleteCall = () => {
        setPayoutDetails(null)
    }

    // Validate form fields
    const validate = () => {
        const newErrors = {};
        if (!formData.country || /\d/.test(formData.country)) {
            newErrors.country = "Country should not contain numbers";
        }
        if (!formData.address1) {
            newErrors.address1 = "Address 1 is required";
        }
        if (!formData.city) {
            newErrors.city = "City is required";
        }
        if (!formData.state) {
            newErrors.state = "State is required";
        }
        if (!formData.postalCode || !/^\d{5,6}$/.test(formData.postalCode)) {
            newErrors.postalCode = "postalCode must be 5 or 6 digits";
        }
        // if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
        //     newErrors.phone = "Phone number must be 10 digits";
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "country") {
            setFormData({ ...formData, country: e.target.value });
            getStateFun(e.target.value);
        }
        if (name === "state") {
            setFormData((prevValue) => ({ ...prevValue, state: e.target.value }));
            const countryCode = formData.country;
            getCityFun(countryCode, e.target.value);
        }
        setFormData({ ...formData, [name]: value });
    };


    const handleLocationChange = async (value) => {
        setFormData((prevValue) => ({
            ...prevValue,
            address1: value
        }));
    };

    const handleLocationSelect = async (value) => {
        try {
            setFormData((prevValue) => ({
                ...prevValue,
                address1: value,
                address2: value,
            }));
        } catch (error) {
            console.error('Error selecting address', error);
        }
    };

    const handleLocationSelect2 = async (value) => {
        try {
            setFormData((prevValue) => ({
                ...prevValue,
                address2: value
            }));
        } catch (error) {
            console.error('Error selecting address', error);
        }
    };
    const handleLocationChange2 = (value) => {
        setFormData((prevValue) => ({
            ...prevValue,
            address2: value
        }));
    };

    const getStateFun = (country) => {
        const stateData = State?.getStatesOfCountry(country).map((state) => ({
            value: state.isoCode,
            displayValue: state.name,
        }));
        setStateList(stateData);
    };

    const getCityFun = (countryCode, stateCode) => {
        const cityData = City?.getCitiesOfState(countryCode, stateCode).map((city) => ({
            value: city.name,
            displayValue: city.name,
        }));
        setCityList(cityData);
    };
    const handleAddressSubmit = async () => {
        if (validate()) {
            setLoading({ ...loading, 'submit': true })
            try {
                const res = await APICALL('/user/addOrUpdatePayout', 'post', formData)
                if (res?.status) {
                    toastifySuccess(res?.message)
                    const newData = res?.data
                    setPayoutDetails({
                        ...payoutDetails,
                        _id: newData?._id,
                        paypalEmail: newData?.paypalEmail,
                        country: newData?.country,
                        address1: newData?.address1,
                        address2: newData?.address2,
                        city: newData?.city,
                        state: newData?.state,
                        postalCode: newData?.postalCode,
                    })
                    handleCloseModal()

                } else {
                    toastifyError(SOMETHING_ERR)
                }
            } catch (error) {
                toastifyError(error?.response?.data?.message || SERVER_ERR)
            } finally {
                setLoading({ ...loading, 'submit': false })
            }
        }
    };

    const coneectStripe = async () => {
        setLoading({ ...loading, 'stripeConnect': true })
        try {
            const params = { userEmail: auth('user')?.email, country: "IE", redirectURL: stripeRedirectURI() + auth('user')?.id };
            const res = await APICALL(`user/stripeVarificationLink`, "post", params);
            window.location.href = res.url;
        } catch (error) {
            console.error(error);
        } finally {
            setLoading({ ...loading, 'stripeConnect': false })
        }
    };

    return (
        <>
            <div className=" selller_edit_sellpromain">
                <div className="nn_edit_proname">Add Payout Method</div>
                <div className='row justify-content-center'>
                    <div className='col-md-5'>
                        <div className="seller_edit_sellpro">
                            <div className="cls_card-body payment_verification text-center">
                                <img width={100} src={verfication} alt='' />
                                <h2>Stripe Verification</h2>
                                <p>
                                    When you receive a payment for a reservation, we
                                    call that payment to you a “payout”. Our secure
                                    payment system supports several payout methods,
                                    which can be setup and edited here. Your available
                                    payout options and currencies differ by country.
                                </p>

                                <p>Click on button & Connect your stripe Account</p>
                                <div>
                                    {
                                        loading?.stripeConnect ?
                                            <BTNLoading className={"myplo_addressbtn1 me-2"} />
                                            :
                                            <>
                                                {
                                                    userInfo?.is_connected ?
                                                        <button type='button' className="myplo_addressbtn1 me-2">
                                                            Stripe Connected
                                                        </button> :
                                                        <>
                                                            <button type='button' className="myplo_addressbtn1 me-2" onClick={() => coneectStripe()}>
                                                                Connect Stripe
                                                            </button>

                                                        </>
                                                }
                                            </>
                                    }
                                </div>

                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div
                    className="modal  fade show Add_addres_modal"
                    style={{
                        display: "block",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        inset: "0px",
                    }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body  p-4 pt-3">
                                <div className="modal-header  pt-0 px-0 pb-2 mb-2">
                                    <h3 className="modal-title">Address</h3>
                                    <button className="close me-0" onClick={handleCloseModal}>
                                        ×
                                    </button>
                                </div>

                                <Form.Group className="mb-3">
                                    <FormLabel>
                                        Paypal Email <span className="text-danger">*</span>
                                    </FormLabel>
                                    <TextField
                                        name="paypalEmail"
                                        fullWidth
                                        variant="filled"
                                        value={formData.paypalEmail}
                                        onChange={handleChange}
                                        error={!!errors.paypalEmail}
                                        helperText={errors.paypalEmail}
                                    />
                                </Form.Group>

                                {/* Country */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Choose Country</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                    >
                                        <option value="">--SELECT--</option>
                                        {countryList?.map((item, i) => (
                                            <option value={item?.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* Address 1 */}
                                <Form.Group className="mb-3">
                                    <div className="group error">
                                        <PlacesAutocomplete
                                            value={formData?.address1}
                                            name="address1"
                                            onChange={handleLocationChange}
                                            onSelect={handleLocationSelect}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="location_input mb-0 w-100">
                                                    <Form.Label>Address 1 *</Form.Label>
                                                    <input className='mb-0' {...getInputProps({ placeholder: 'Start Typing' })} />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading ? <div>Loading...</div> : null}
                                                        {suggestions?.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active ? 'whitesmoke' : '#fff',
                                                                padding: '10px 10px',
                                                                cursor: 'pointer'
                                                            };
                                                            return (
                                                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                                    {suggestion.description}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        <span className="errmsg">{errors.address1}</span>
                                    </div>
                                </Form.Group>

                                {/* Address 2 */}
                                <Form.Group className="mb-3">
                                    <div className="group error">
                                        <PlacesAutocomplete
                                            value={formData?.address2}
                                            name="address2"
                                            onChange={handleLocationChange2}
                                            onSelect={handleLocationSelect2}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="location_input mb-0 w-100">
                                                    <Form.Label>Address 2 *</Form.Label>
                                                    <input className='mb-0' {...getInputProps({ placeholder: 'Start Typing' })} />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading ? <div>Loading...</div> : null}
                                                        {suggestions?.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active ? 'whitesmoke' : '#fff',
                                                                padding: '10px 10px',
                                                                cursor: 'pointer'
                                                            };
                                                            return (
                                                                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                                                                    {suggestion.description}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        <span className="errmsg">{errors.address2}</span>
                                    </div>
                                </Form.Group>

                                {/* State */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Choose State</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                    >
                                        <option value="">--SELECT--</option>
                                        {stateList?.map((item, i) => (
                                            <option value={item?.value}>
                                                {item.displayValue}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* City */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Choose City</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                    >
                                        <option value="">--SELECT--</option>
                                        {cityList?.map((item, i) => (
                                            <option value={item?.value}>
                                                {item.displayValue}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* postalCode */}
                                <Form.Group className="mb-3">
                                    <FormLabel>
                                        postalCode <span className="text-danger">*</span>
                                    </FormLabel>
                                    <TextField
                                        name="postalCode"
                                        fullWidth
                                        variant="filled"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        error={!!errors.postalCode}
                                        helperText={errors.postalCode}
                                    />
                                </Form.Group>

                                <div className="text-center">
                                    {
                                        loading?.submit ?
                                            <BTNLoading className={"myplo_addressbtn1 mt-2"} />
                                            :
                                            <Button className="myplo_addressbtn1 mt-2" type="button" onClick={() => handleAddressSubmit()}>
                                                Next
                                            </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default PayoutMethod
