import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SellYourPartyleftover from './SellYourPartyleftover';

const UploadpartleftOverBtn = () => {
  const [isPaneOpen, setIsPaneOpen] = React.useState(false);
  const openSlidingPane = () => {
    setIsPaneOpen(true);
  };
  return (
    <>
      <Container className="mb-md-5 pb-md-4 pb-3">
        <Row className="justify-content-center">
          <Col xl={7} lg={6}>
            <div className="myplo_btn_wrapper">
              <Button onClick={openSlidingPane}   className="cmn-btn2">
                Upload Your Party Leftovers
              </Button>
              <Link to={"/categories"} className="cmn-btn2">
                Browse Party Leftovers
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <SellYourPartyleftover isPaneOpen={isPaneOpen} setIsPaneOpen={setIsPaneOpen}></SellYourPartyleftover>
    </>

  )
}

export default UploadpartleftOverBtn
