/* eslint-disable */
import React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {TableContainer, TablePagination  } from '@mui/material';
import { useEffect } from 'react';
import { APICALL } from 'helper/api/api';
import { TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from 'helper/Constant';
import PageLoader from 'helper/my-com/PageLoader';
import NoData from 'helper/my-com/NoData';
import { timeAgo } from 'helper/Utility';
export default function NewsLetter() {
    const [data, setData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const [loader, setLoader] = useState({
        'pageLoading': false
    })
    const getDataListFun = async () => {
        setLoader({ ...loader, 'pageLoading': true })
        try {
            const res = await APICALL('user/getSubscriberList', "post", {})
            console.log(res)
            if (res?.status) {
                setData(res?.data)
            } else {
                setData([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader({ ...loader, 'pageLoading': false })
        }
    }

    useEffect(() => {
        getDataListFun()
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span> Newsletter
                </typography>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box className="boxtbale">
                    {
                        loader?.pageLoading ?
                            <PageLoader />
                            :
                            <>
                                {
                                    data.length > 0 ?
                                        <>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>S.No</TableCell>
                                                            <TableCell>Email</TableCell>
                                                            <TableCell>Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((row, i) => (
                                                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.email}</TableCell>
                                                                    <TableCell>{timeAgo(row.createdAt)}</TableCell>
                                                                    <TableCell align="right">
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                component="div"
                                                count={data?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />

                                        </>
                                        :
                                        <NoData />
                                }
                            </>
                    }

                </Box>
            </Box>
        </>
    );
}