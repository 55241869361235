import { APICALL } from 'helper/api/api'
import FrontPageLoader from 'helper/my-com/FrontPageLoader';
import HTMLContent from 'helper/my-com/HTMLContent';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import About from './About';
import { imgBaseURL } from 'helper/Utility';
const StaticPages = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const [pageData, setPageData] = useState(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getPageData()
    }, [lastSegment])
    const getPageData = async () => {
        setLoading(true)
        try {
            const res = await APICALL('admin/getAllStaticPages', 'post', { pageUrl: lastSegment })
            if (res?.status) {
                setPageData(res?.data)
            } else {
                setPageData(null)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            {
                loading ? <FrontPageLoader />
                    :
                    lastSegment === "about-us" ?
                        <About pageData={pageData} />
                        :
                        <div className="margindiv-header">
                            <div className="myplo_aboutus_page">
                                <>
                                    <div className="container">
                                        <div className="myplo-title-h2 text-center d-block">
                                            <h2><span className="shape"></span>{pageData?.pageName}</h2>
                                        </div>
                                        <div className="priourpg">
                                            {
                                                pageData?.image &&
                                                <div className="row mb-5">
                                                    <div className="col-md-6 col-12">
                                                        <img src={imgBaseURL() + pageData?.image} alt="img" />
                                                    </div>
                                                </div>
                                            }
                                            <HTMLContent data={pageData?.content} />
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
            }
        </>
    )
}

export default StaticPages