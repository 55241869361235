import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import aboutimg from "../../assets/img/aboutimg.jpeg";
import about1 from "../../assets/img/about1.jpeg";
import about2 from "../../assets/img/about2.jpeg";
import about3 from "../../assets/img/about3.jpeg";
import about4 from "../../assets/img/about4.jpeg";
import about5 from "../../assets/img/about5.jpeg";
import about6 from "../../assets/img/about6.jpeg";
import playstore from "../../assets/img/badge_googleplay.svg";
import appstore from "../../assets/img/badge_appstore.svg";
import myploscreen from "../../assets/img/footerBatch.png";
import UploadpartleftOverBtn from "views/Component/UploadpartleftOverBtn";
import { imgBaseURL } from "helper/Utility";
import HTMLContent from "helper/my-com/HTMLContent";
const About = ({pageData}) => {
  return (
    <>
      <div className="margindiv-header">
        <div className="myplo_aboutus_page">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="myplo-title-h2">
                  <h2>
                    <span className="shape"></span>{pageData?.pageName}
                  </h2>
                </div>

                <section className="about-para">
                <HTMLContent data={pageData?.content}/>
                </section>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="hero-man d-none d-lg-block f-right">
                  <img src={imgBaseURL() + pageData?.image} alt="img" />
                </div>
              </div>
            </div>

            <div className="mt-5 row">
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about1} alt="img" />
              </div>
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about2} alt="img" />
              </div>
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about3} alt="img" />
              </div>
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about4} alt="img" />
              </div>
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about5} alt="img" />
              </div>
              <div className="mb-3 col-md-4 col-sm-4">
                <img src={about6} alt="img" />
              </div>
            </div>

          </div>
        </div>
        <UploadpartleftOverBtn />
        <section className="free-app-store">
          <Container>
            <Row>
              <Col md="10" className="mx-auto">
                <Row className="align-items-end">
                  <Col md="4" className="d-md-block d-none">
                    <div className="myplo_footertopimg">
                      <img alt="" src={myploscreen} className="img-fluid" />
                    </div>
                  </Col>
                  <Col md="8 px-md-5">
                    <div className="mpplo_download_appcnt">
                      <h3>
                        GET THE <span className="free-app">FREE</span>{" "}
                        <span>APP</span> NOW!
                      </h3>
                      <div className="myplo_footerapps">
                        <div className="myplo_googlepay">
                          <img alt="" src={playstore} />
                        </div>
                        <div className="myplo_googlepay">
                          <img src={appstore} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default About;
