import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APICALL } from 'helper/api/api';
import { useState } from 'react';
import { useEffect } from 'react';
import { defaultIMG, imgBaseURL } from 'helper/Utility';
import { useFrontDataContext } from 'helper/context/FrontContextProvider';
import NoData from 'helper/my-com/NoData';
import FrontPageLoader from 'helper/my-com/FrontPageLoader';

const CategoryList = ({ role, type }) => {
  const { contextLoader, getCategoryListFunc, vendorCategoryList, categoryList } = useFrontDataContext()
  useEffect(() => {
    getCategoryListFunc(role);
  }, [role]);

  return (
    <>
      {
        contextLoader?.category ? <FrontPageLoader /> :
          categoryList?.length > 0 ?
            <ul className={` ${role == "vendorService" ? "vendor_onlycategries" : "main_onlycategries"} `}>
              {
                role == "vendorService" ?
                  (type === "all" ? vendorCategoryList : vendorCategoryList?.slice(0, 5)).map((category, i) => (
                    <>
                      {
                        <li key={i}>
                          <Link to={`/vendor-listing/${category?._id}`}>
                            <div className="vendor_onlycategries_img">
                              <img src={category?.image ? imgBaseURL() + category?.image : defaultIMG} alt={category.categoryName} className="cateimg category-icon" />
                            </div>
                            <h2 className="vendor_onlycategries_title" >
                              {category.categoryName}
                            </h2>
                          </Link>
                        </li>
                      }
                    </>
                  ))
                  :
                  (type === "all" ? categoryList : categoryList?.slice(0, 5)).map((category, i) => (
                    <>
                      {
                        <li key={i}>
                          <Link to={`/product-listing/${category?._id}`}>
                            <div className="main_onlycategries_img">
                              <img src={category?.image ? imgBaseURL() + category?.image : defaultIMG}
                                alt={category.categoryName}
                                className="cateimg category-icon"
                              />
                            </div>
                            <h2 className="main_onlycategries_title" href={`/category-product/${category._id}`}>
                              {category.categoryName}
                            </h2>
                          </Link>
                        </li>
                      }
                    </>
                  ))

              }
            </ul>
            :
            <NoData />
      }
    </>
  );
};

export default CategoryList;
