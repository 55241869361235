import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../assets/img/products5.png";
import { Link, useParams } from "react-router-dom";
import "react-input-range/lib/css/index.css";
import AppPlayStore from "views/Component/AppPlayStore";
import { useEffect } from "react";
import { useState } from "react";
import { APICALL } from "helper/api/api";
import ProductBox from "views/Component/ProductBox";
import emtycard from "../../assets/img/empty-tap.jpg";
import { currencySign } from "helper/Constant";
import { defaultIMG, defaultUserIMG, imgBaseURL } from "helper/Utility";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
import { Rating } from "@mui/material";

const VendorServiceListing = () => {
  const { id } = useParams()
  const [productList, setProductList] = useState([])
  const [categoryObj, setCategoryObj] = useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (id) {
      getProductListFunc()
    }
  }, [id])
  const getProductListFunc = async () => {
    setLoading(true)
    try {
      const params = {
        "categoryId": id,
        "type": "vendorService",
      }
      const res = await APICALL("user/getProductsByCategoryId", 'post', params)
      console.log("res", res)
      if (res?.status) {
        setCategoryObj(res?.categoryData)
        setProductList(res?.data)
      } else {
        setProductList([])
      }
    } catch (error) {
      setProductList([])
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="margindiv-header">
        <section className="prodcuts_listing_page">
          {
            loading ? <FrontPageLoader />
              :
              <Container>
                <Row>
                  <Col xl="8" lg="7" md="10">
                    <div className="myplo-title-h2 d-block">
                      <h2 className="ps-0 mb-2">
                        <span className="shape"></span>{categoryObj?.categoryName}
                      </h2>
                    </div>
                  </Col>
                </Row>
                <div className="prodcuts_listing_flex mt-2 w-100">
                  <div className="prodcuts_listing_colright   w-100 pb-lg-4">
                    {
                      productList?.length > 0 ?
                        <div className="row">
                          {
                            productList?.map((item, i) => (
                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4">
                                <div className="product-boxes">
                                  <Link to={`/services-details/${item?._id}/1`}>
                                    <div className="nn_homproductctn">
                                      <img alt="" src={item?.gallery?.length > 0 ? imgBaseURL() + item?.gallery[0] : defaultIMG} />
                                    </div>
                                    <div className="product-boxes_inner">
                                      <div className="inner-catgigstitle">
                                        <div className="d-flex align-items-center">
                                          <img src={item?.userId?.avatar ? imgBaseURL() + item?.userId?.avatar : defaultUserIMG} alt="" style={{ height: "35px", width: "35px", borderRadius: "100%", marginRight: "8px" }} />
                                          <h4>{item?.title}</h4>
                                        </div>
                                        <h6> {currencySign}{item?.price}</h6>
                                      </div>
                                      <Rating name="read-only" value={item?.userId?.averageRating} readOnly />
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            ))
                          }

                        </div>
                        :
                        <>
                          <div className="myplo_notFound">
                            <img src={emtycard} alt="empty" />
                            <h5> OOPS! NO RESULTS NEAR YOU</h5>
                            <span>
                              Try looking for something else or check again soon!
                            </span>
                          </div>
                        </>
                    }

                  </div>
                </div>
              </Container>
          }
        </section>
        <AppPlayStore />
      </div>
    </>
  );
};

export default VendorServiceListing;
