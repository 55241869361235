import React from "react";

// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import blog from "../../../src/assets/img/blog.jpeg";
import playstore from "../../../src/assets/img/badge_googleplay.svg";
import appstore from "../../../src/assets/img/badge_appstore.svg";
import myploscreen from "../../../src/assets/img/footerBatch.png";
import { Link, useNavigate } from "react-router-dom";
import { APICALL } from "helper/api/api";
import { useState } from "react";
import { useEffect } from "react";
import { imgBaseURL } from "helper/Utility";
import NoData from "helper/my-com/NoData";
import PageLoader from "helper/my-com/PageLoader";
import FrontPageLoader from "helper/my-com/FrontPageLoader";
const Blog = () => {
  const navigate = useNavigate()
  const [blogList, setBlogList] = useState([])
  const [loading, setLoading] = useState(false)
  const getBlogListFun = async () => {
    setLoading(true)
    try {
      const res = await APICALL("admin/getActiveBlogs", "POST", {})
      if (res?.status) {
        setBlogList(res?.data)
      } else {
        setBlogList([])
      }
    } catch (error) {
      console.log(error)
      setBlogList([])
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getBlogListFun()
  }, [])

  const handleRedirect = (item) => {
    navigate(`/blog-details/${item?._id}`, { state: item })
  }

  return (
    <>
      <div className="margindiv-header">
        <div className="myplo_aboutus_page">
          <Container>
            <div className="myplo-title-h2 text-center d-block">
              <h2>
                <span className="shape"></span>Blog
              </h2>
            </div>
            <div className="row">

              {
                loading ? <FrontPageLoader /> :
                  blogList?.length > 0 ?
                    blogList?.map((item, i) => (
                      <div className="col-lg-4 col-md-6 col-xs-12 mb-4">
                        <div className="blog-item" onClick={() => handleRedirect(item)} style={{ cursor: "pointer" }}>
                          <Link to="#" >
                            <figure className="image-holder">
                              <img src={item?.image ? imgBaseURL() + item?.image : blog} alt="Blog Post " />
                            </figure>
                          </Link>
                          <div className="card-details-blog">
                            <div className="date-line">{item?.createdAt}</div>
                            <h4 className="title">
                              <Link className="-unlink" to="#" onClick={() => handleRedirect(item)}>
                                {item?.title}
                              </Link>
                            </h4>

                            <Link className="button-text" to="#" onClick={() => handleRedirect(item)}>
                              Read More
                              <i className="fa fa-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    <div>
                      <NoData />
                    </div>
              }

            </div>
          </Container>
        </div>

        <section className="free-app-store">
          <Container>
            <Row>
              <Col md="10" className="mx-auto">
                <Row className="align-items-end">
                  <Col md="4" className="d-md-block d-none">
                    <div className="myplo_footertopimg">
                      <img alt="" src={myploscreen} className="img-fluid" />
                    </div>
                  </Col>
                  <Col md="8 px-md-5">
                    <div className="mpplo_download_appcnt">
                      <h3>
                        GET THE <span className="free-app">FREE</span>{" "}
                        <span>APP</span> NOW!
                      </h3>
                      <div className="myplo_footerapps">
                        <div className="myplo_googlepay">
                          <img alt="" src={playstore} />
                        </div>
                        <div className="myplo_googlepay">
                          <img src={appstore} alt="" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Blog;
