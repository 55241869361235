import { APICALL } from 'helper/api/api';
import { SERVER_ERR } from 'helper/Constant';
import { auth, toastifyError, toastifySuccess } from 'helper/Utility';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
const ContextData = createContext();
export const useFrontDataContext = () => useContext(ContextData);
export const FrontContextProvider = ({ children }) => {
    const [productList, setProductList] = useState([])
    const [generalSetting, setGeneralSetting] = useState(null)
    const [headerContent, setHeaderContent] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [userInfoByID, setUserInfoByID] = useState(null)
    const [categoryList, setCategoryList] = useState([])
    const [vendorCategoryList, setVendorCategoryList] = useState([])

    const navigate = useNavigate()
    const [contextLoader, setContextLoader] = useState({
        'category': false,
        'user': false,
        'product': false,
        'addToCart': {},
        "userDetails": false
    })
    const authID = auth('user')?.id
    useEffect(() => {
        if (auth('user')) {
            getUserInfoFun()
        }
    }, [authID])

    const getUserInfoFun = async (id) => {
        try {
            setContextLoader({ ...contextLoader, 'userDetails': true })
            const res = await APICALL('user/getUserData', 'post', { userId: id ? id : authID })
            if (res?.status) {
                setUserInfo(res?.data)
            } else {
                setUserInfo(null)
            }
        } catch (error) {
            setUserInfo(null)
        } finally {
            setContextLoader({ ...contextLoader, 'userDetails': false })
        }
    }

    const [isStripeConncted, setIsStripeConnected] = useState(false)
    const stripeConnectedFun = async () => {
        try {
            const res = await APICALL('user/checkStripeAccountStatus', 'post', { accountId: userInfo?.stripeAccountId })
            if (res?.status) {
                setIsStripeConnected(res?.status)
            } else {
                setIsStripeConnected(false)
            }
        } catch (error) {
            setIsStripeConnected(false)
        }
    }

    const addProductInWishlistFun = async (id) => {
        try {
            const param = { productId: id }
            const res = await APICALL('/user/addOrRemoveFavourite', "post", param)
            if (res?.status) {
                toastifySuccess(res?.message)
            } else {
                toastifyError('Product can not be added in your Favourite')
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || SERVER_ERR)
        }
    }

    const reportProductFun = async (id) => {
        try {
            const param = { productId: id }
            const res = await APICALL('/user/reportProduct', "post", param)
            if (res?.status) {
                toastifySuccess(res?.message)
            } else {
                toastifyError('Product can not be reported !!')
            }
        } catch (error) {
            toastifyError(error?.response?.data?.message || SERVER_ERR)
        }
    }

    const getGeneralSettingFun = async () => {
        try {
            const res = await APICALL('/admin/getGeneralSettings', 'post', {})
            if (res?.status) {
                setGeneralSetting(res?.data)
            } else {
                setGeneralSetting(null)
            }
        } catch (error) {
            setGeneralSetting(null)
        }
    }

    const getUserByIDFun = async (id) => {
        try {
            const res = await APICALL('/user/userData', 'post', { id: id })
            if (res?.status) {
                setUserInfoByID(res?.user)
            } else {
                setUserInfoByID(null)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getCategoryListFunc = async (role, loader) => {
        if (!loader) {
            setContextLoader({ ...contextLoader, 'category': true })
        }
        try {
            const res = await APICALL('admin/getActiveCategories', 'post', { type: role })
            if (res?.status) {
                if (role == "vendorService") {
                    setVendorCategoryList(res?.data)
                } else {
                    setCategoryList(res?.data)
                }
            } else {
                setCategoryList([])
            }
        } catch (error) {
            setCategoryList([])
        } finally {
            setContextLoader({ ...contextLoader, 'category': false })
        }
    }


    const getProductListFun = async (obj) => {
        setContextLoader({ ...contextLoader, 'product': true })
        try {
            const params = {
                limit: obj?.limit || 10,
                categoryId: obj?.category_id
            };
            const res = await APICALL("user/allArtwork", "post", params);
            if (res?.status) {
                setProductList(res?.data);
                setContextLoader({ ...contextLoader, 'product': false })
            } else {
                setProductList([]);
                setContextLoader({ ...contextLoader, 'product': false })
            }
        } catch (error) {
            setProductList([]);
            setContextLoader({ ...contextLoader, 'product': false })
        }
    };

    const getHeaderContent = async () => {
        try {
            const res = await APICALL('admin/getAllCategoriesWithSubcategories', 'post', {})
            if (res?.status) {
                setHeaderContent(res?.data)
            } else {
                setHeaderContent(null)
            }
        } catch (error) {
            setHeaderContent(null)
        }
    }


    return (
        <ContextData.Provider value={{
            contextLoader,
            getCategoryListFunc, categoryList, vendorCategoryList,
            addProductInWishlistFun,
            reportProductFun,
            userInfo, getUserInfoFun,
            getGeneralSettingFun, generalSetting,
            stripeConnectedFun, isStripeConncted,

            getProductListFun, productList,
            getHeaderContent, headerContent,
            userInfoByID, getUserByIDFun
        }}>
            {children}
        </ContextData.Provider>
    );
};